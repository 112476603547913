<template>
    <div>
        <div v-if="p_auth == 0" class="row d-flex justify-content-around">
            <div class="col-md-5 px-3 py-5 mb-5 mb-md-0 border d-flex flex-column align-items-center">
                <h2 class="h3 text-center fw-bold">初めての方へ</h2>
                <p>お申込みするにはアカウントの作成が必要です。こちらからアカウント作成を行ってください。</p>
                <a class="btn btn-primary btn-lg" role="button" :href="p_route_register">アカウント作成</a>
            </div>
            <div class="col-md-5 px-3 py-5 border d-flex flex-column align-items-center">
                <h2 class="h3 text-center fw-bold">アカウント作成済みの方へ</h2>
                <p>お申込される場合はMyページへログインする必要があります。こちらからログインしてください。</p>

                <a class="login btn btn-primary btn-lg" :href="p_route_login">ログイン</a>
            </div>
        </div>
        <template v-if="p_auth == 1">
            <div v-if="p_verify_success == 1" class="alert alert-success text-center">
                アカウントの認証が完了しました。お申込みが可能です。
            </div>
            <!--認証エラーの場合はエラーメッセージを表示-->
            <div v-else-if="p_verify_error == 1" class="alert alert-danger text-center">
                アカウントの認証が出来ませんでした。再度認証をお試しいただくか、何度試しても認証出来ない場合はお問い合わせ先までご連絡ください。
            </div>
            <!--アカウント認証が済んでいない場合はメッセージを表示-->
            <div v-if="p_verified == 0" class="alert alert-danger text-center">
                アカウントの認証が完了していません。<br>
                アカウントのメールアドレスに送信された認証用のメールから認証を行ってください。<br><br>

                認証メールが届かない場合は迷惑メールに入っている可能性のほかに、携帯電話メールサービスの迷惑メールフィルタによりメールが届かない、または容量制限で受け取れていない可能性があります。<br><br>
                その場合は<a href="https://oua.osaka-geidai.ac.jp/geidai/kh/mail/" target="_blank">メールの受信設定について</a>をご参照いただき、<br>
                必要な設定を行っていただいた後、<a :href="p_url + 'email/verify'">アカウントの認証</a>から認証用メールを再度送信してください。<br>
            </div>

        </template>


    </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, onMounted, onUpdated } from "vue";

export default defineComponent({
    props: {
        p_auth : {
            type : Number,
            default : 0
        },
        p_verify_success: {
            type : Number,
            default : 0
        },
        p_verify_error: {
            type : Number,
            default : 0
        },
        p_verified: {
            type : Number,
            default : 0
        },
        p_route_register : {
            type : String,
            default : 'register'
        },
        p_route_login : {
            type : String,
            default : 'login?return_to_welcome=1'
        },
        p_url : {
            type : String,
            default : ''
        }

    },
    setup(props, context) {


    },
    methods: {

    }
})

</script>

<style scoped lang="scss">

</style>
