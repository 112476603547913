<template>
    <div>
        <div class="row mb-3">
            <p>時間割</p>
            <div class="col-md-6 mb-3">
                <select v-model="d_selected_timetable_category_id" @change="change_timetable_cateories" aria-label="スケジュール" name="timetable_category" class="form-select">
                    <option v-bind:value="0">すべて</option>
                    <option v-for="d in d_timetable_categoies" :value='d.id'>{{ d.title }}</option>
                </select>
            </div>

            <div class="col-md-6 mb-3">
                <select v-model="d_selected_timetable_id" aria-label="スケジュール" name="timetable_id" class="form-select">
                    <option v-bind:value="0">未選択</option>
                    <option v-for="d in d_timetables" :value='d.id'>{{ d.title }}</option>
                </select>
            </div>

        </div>
    </div>
</template>

<script>
    import { defineComponent, ref, onMounted, inject } from "vue";
    import moment from "moment";
    import axios from "axios";
    //import TimetablesCategories from './TimetablesCategories';

    export default defineComponent({
        name: "AdminTimetables",
        /*
        components: {
            'timetables-categories': TimetablesCategories
        },
        */

        props: {
            p_selected_timetable_category_id : {
                type : Number,
                default : 0
            },
            p_selected_timetable_id : {
                type : Number,
                default : 0
            },
            p_blank_value : {
                type : Number,
                default : 0
            },
            p_blank_name : {
                type : String,
                default : '未選択'
            },
            p_old: {
                type : undefined,
                default : null
            },


        },

        setup(props) {
            //Data
            //-----------------------------------
            const error = ref(null);
            const d_timetables = ref([]);
            const d_timetable_categoies = ref([]);
            const d_selected_timetable_category_id = props.p_selected_timetable_category_id;
            const d_selected_timetable_id = props.p_selected_timetable_id;

            //injection
            //-----------------------------------
            const i_api_url = inject('api_url');

            onMounted(() => {
                get_timetable_cateories();
                get_timetables(0);
            });


            //Methods
            //-----------------------------------
            const get_timetable_cateories = () => {
                let url = i_api_url + "admin/timetables/categories/";
                axios.get(url).then(response => {
                    d_timetable_categoies.value = response.data;
                });
            };

            const change_timetable_cateories = (event) => {
                var id= event.target.value;
                get_timetables(id);
            };

            const get_timetables = (category_id) => {

                let url = i_api_url + "admin/timetables/get/" + category_id;
                axios.get(url).then(response => {
                    var data =[];
                    Object.keys(response.data).forEach(function (key) {
                        var id = response.data[key]['id'];
                        var title = response.data[key]['title'];
                        var now =  moment().format("YYYY-MM-DD");
                        var start = moment(now + ' ' + response.data[key]['start']).format('HH:mm');
                        var end = moment(now + ' ' + response.data[key]['end']).format('HH:mm');
                        data.push(
                            {
                                id : id,
                                title : title  + ' (' +  start  + '〜' + end + ')'
                            }
                        );
                    });

                    d_timetables.value = data;
                });

            }





            return {
                error,
                d_timetables,
                d_timetable_categoies,
                d_selected_timetable_category_id,
                d_selected_timetable_id,
                i_api_url,
                get_timetable_cateories,
                change_timetable_cateories,
                get_timetables,
            }

        },

        methods: {
            moment: function (date) {
                return moment(date).format('YYYY年MM月DD日');
            }
        }
    })
</script>
