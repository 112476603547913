<template>
    <div>

        <template v-if="d_schedules.length > 0">
            <mv
                :p_schedules="d_schedules"
                :p_selected_schedule_id="d_selected_schedule_id"
                :p_image_path_schedules = p_image_path_schedules
                :p_message_front_logged_in="d_message_front_logged_in"

                ref="r_mv"
            ></mv>
        </template>


        <template v-if="d_front_contents">
            <contents
                :p_auth = p_auth
                :p_verify_success = p_verify_success
                :p_verified = p_verified
                :p_message_front="d_message_front"
                :p_message_front_logged_in="d_message_front_logged_in"
                :p_message_front_logged_out="d_message_front_logged_out"
            ></contents>
        </template>

        <register
            :p_auth = p_auth
            :p_verify_success = p_verify_success
            :p_verify_error = p_verify_error
            :p_verified = p_verified
            :p_route_register = p_route_register
            :p_route_login = p_route_login
            :p_url = p_url
        ></register>

        <message
            :p_message = p_message
            :p_error = p_error
            :p_error_classes = p_error_classes
        ></message>




        <input v-if="d_selected_schedule_id" type="hidden" name="schedule_id" :value="d_selected_schedule_id">
        <template v-if="d_classes" v-for="(classes_data, i) in d_classes" :key="classes_data">
            <input type="hidden" :name="'selected_classes[' + classes_data['timetable_id'] + '][classes_id]'" value="">
            <input type="hidden" :name="'selected_classes[' + classes_data['timetable_id'] + '][classes_category_id]'" value="">
        </template>


        <div class="row mb-3">
            <ul class="nav nav-tabs schedules m-0 p-0 mt-5" role="tablist">
                <li class="nav-item nav-links" role="presentation" v-if="d_data_loaded && d_schedules.length > 1" v-for="(d, i) in d_schedules" :key="d" :id="'schedule_' + d.id">
                    <a v-if="i==0" v-bind:href="'#schedule_' + d.id" v-on:click="select_schedule(d.id, d_first_loaded)" class="nav-link active py-4" role="tab" data-bs-toggle="tab" v-bind:aria-controls="'schedule_'+ i" aria-selected="true">
                        <!-- <img v-if="d.image!=''" v-bind:src="d_image_path_schedule + '/' + d.image" v-bind:alt="d.title">
                        <img v-else v-bind:src="p_blank_image_schedule" v-bind:alt="d.title">-->
                        {{d.image}}
                        <p class="m-0">{{ moment(d.date) }}<br>
                        {{d.title}}</p>
                    </a>
                    <a v-else v-bind:href="'#schedule_' + d.id" v-on:click="select_schedule(d.id)" class="nav-link py-4" role="tab" data-bs-toggle="tab" v-bind:aria-controls="'schedule_'+ i" aria-selected="true">
                        <!--<img v-if="d.image!=''" v-bind:src="d_image_path_schedule + '/' + d.image" v-bind:alt="d.title">-->
                        <!--<img v-else v-bind:src="p_blank_image_schedule" v-bind:alt="d.title">-->
                        <p class="m-0">{{ moment(d.date) }}<br>
                        {{d.title}}</p>
                    </a>
                </li>
            </ul>
        </div>

        <article>


            <div v-if="d_selected_schedule" class="classes_header">
                <!--<img v-if="d_selected_schedule.image" v-bind:src="d_domain + '/' + d_image_path_schedule + '/' + d_selected_schedule.image" v-bind:alt="d_selected_schedule.title">-->
                <h2>■ {{ moment_schedule(d_selected_schedule.date) }} {{d_selected_schedule.title}}</h2>
            </div>




            <div v-for="(d, i) in d_classes" :key="d" class="classes_group">
                <div class="classes">
                    <h3 class="text-center py-3 px-2 m-0" v-if="exist_timetable(d.timetable_id)">{{ get_timetable_title(d.timetable_id) }} ({{ get_timetable_start(d.timetable_id)}} 〜 {{ get_timetable_end(d.timetable_id)}})</h3>
                    <div class="inner d-flex flex-wrap">
                        <section v-if="d.classes.length" v-for="(e, l) in d.classes" :key="e" :class="['class', {authed : p_auth}, get_classes_status(e.id)]" :data-classes-schedule-id="e.schedule_id" :data-classes-timetable-id="e.timetable_id" :data-classes-id="e.id" key="l">
                            <classes-card
                                v-bind="e.has_category? {p_modal:true}:false"
                                :ref="el => { if (el) r_classes[e.id] = el }"
                                :p_dev=p_dev
                                :p_auth=p_auth
                                :p_verified=p_verified
                                :p_class="e"
                                :p_id="e.id"
                                :p_option_id="e.option_id"
                                :p_title="e.title"
                                :p_group_id="e.group_id"
                                :p_image="e.image"
                                :p_content="e.content"
                                :p_note="e.note"
                                :p_schedule="get_schedule(e.schedule_id)"
                                :p_timetable="get_timetable(e.timetable_id)"
                                :p_status="get_classes_status(e.id)"
                                :p_option="get_option(e.option_id)"
                                :p_option_categories="get_option_category(e.option_id)"
                                :p_classes_categories="get_classes_categories(e.id)"
                                :p_application_details="get_application_details(e.id)"
                                :p_applied="get_applied(e.id)"
                                :p_applicable_school="get_applicable_school(e.schedule_id)"
                                :p_user_school=p_user_school
                                :p_schedule_school="get_schedule_school(e.schedule_id)"
                                :p_student_names=p_student_names
                                @parent_select_classes="select_classes"
                                @parent_unselect_classes="unselect_classes"
                                @parent_classes_categories="open_classes_category"
                                @parent_not_auth="not_auth"
                            ></classes-card>
                        </section>
                    </div>
                </div>
            </div>

            <div class="modals">
                <template v-for="(d, i) in d_classes" :key="d">
                    <template v-if="d.classes.length" v-for="(e, l) in d.classes" :key="e">
                        <transition name="fade">
                            <classes-modal-option
                                v-if="e.has_category"
                                :ref="el => { if (el) r_modals[e.id] = el }"
                                :p_auth=p_auth
                                :p_verified=p_verified
                                :p_class="e"
                                :p_classes_id="e.id"
                                :p_option_id="e.option_id"
                                :p_option="get_option(e.option_id)"
                                :p_option_categories="get_option_category(e.option_id)"
                                :p_schedule="get_schedule(e.schedule_id)"
                                :p_timetable="get_timetable(e.timetable_id)"
                                :p_classes_categories="get_classes_categories(e.id)"
                                :p_application_details="get_application_details(e.id)"
                                :p_applied="get_applied(e.id)"
                                :p_user_school=p_user_school
                                @parent_select_option_category="select_option_category"
                            ></classes-modal-option>
                        </transition>
                    </template>
                </template>
            </div>
        </article>


    </div>
    <application-button
        :ref="r_application_button"
        :p_auth=p_auth
        :p_verified=p_verified
    ></application-button>



    <classes-modal-auth v-if="p_auth == 0 || p_verified == 0"
        :ref="r_toast_auth"
    ></classes-modal-auth>

</template>

<script>

    import { defineComponent, ref, onMounted, onBeforeUpdate, onUpdated, nextTick, inject } from "vue";
    import axios from "axios";
    import moment from "moment";
    import FrontClassesCard from './ClassesCard';
    import FrontClassesModalOption from './ClassesModalOption';
    import FrontClassesModalAuth from './ClassesModalAuth';
    import ApplicationButton from './ApplicationButton';
    import Message from './Message';
    import MV from './MV';
    import Register from './Register';
    import Contents from './Contents';



    // 外部JSファイルのモジュールをインポートします。
    // モジュール名"Export"は任意です。
    // JSファイル名の".js"は記述しません。
    import ClassesConditions from "./ClassesConditions";
    import ClassesGroups from "./ClassesGroups";


    export default defineComponent({
        name: "FrontClasses",
        components: {
            'classes-card': FrontClassesCard,
            'classes-modal-option': FrontClassesModalOption,
            'classes-modal-auth': FrontClassesModalAuth,
            'application-button': ApplicationButton,
            'message': Message,
            'mv': MV,
            'register': Register,
            'contents': Contents,

        },

        props: {
            p_auth : {
                type : Number,
                default : 0
            },
            p_verified : {
                type : Number,
                default : 0
            },
            p_verify_success : {
                type : Number,
                default : 0
            },
            p_verify_error : {
                type : Number,
                default : 0
            },
            p_route_register : {
                type : String,
                default : 'register'
            },
            p_route_login : {
                type : String,
                default : 'login?return_to_welcome=1'
            },
            p_dev : {
                type : Number,
                default : 0
            },
            p_selected_schedule_id : {
                type : Number,
                default : 0
            },
            p_image_path_classes : {
                type : String,
                default : 'storage/images/classes'
            },
            p_image_path_schedules : {
                type : String,
                default : 'storage/images/schedules'
            },
            p_blank_image_schedule : {
                type : String,
                default : '/img/class_image_blank.png'
            },

            p_old: {
                type : undefined,
                default : null
            },
            p_loaded : {
                type : Boolean,
                default : false
            },
            p_user_school : {
                type : Number,
                default : 0
            },
            p_student_names : {
                type : undefined,
                default : null
            },
            p_message :{
                type : String,
                default : ''
            },
            p_error : {
                type : Number,
                default : 0
            },
            p_error_classes : {
                type : undefined,
                default : null
            },
            p_url : {
                type : String,
                default : ''
            }
        },


        setup(props, context) {
            //Data
            const error = ref(null);
            const d_front_contents = ref(null);
            const d_message_front = ref('');
            const d_message_front_logged_in = ref('');
            const d_message_front_logged_out = ref('');
            const d_schedules = ref([]);
            const d_schedules_index = ref({});
            const d_timetables = ref({});
            const d_active_classes = ref({});
            const d_classes = ref([]);
            const d_classes_index = ref({});
            const d_classes_categories = ref({});
            const d_classes_conditions = ref({});
            const d_classes_groups = ref({});
            const d_options = ref({});
            const d_options_categories = ref({});
            const d_application_details = ref({});
            const d_arr_options_categories = ref({});
            const d_selected_schedule = ref(null);
            const d_selected_schedule_id = ref(null);
            const d_capacity = ref({}); //申込可能人数
            const d_application_number = ref({}); //申込人数
            const d_status = ref({}); //ステータス
            const d_image_path_schedules  = ref(props.p_image_path_schedules);
            const d_image_path_classes = ref(props.p_image_path_classes);
            const d_domain = ref(window.location.protocol + '//' + window.location.host);
            const d_selected_classes = ref({});
            const d_select_classes_id = ref(0);
            const d_select_option_id = ref(0);
            const d_select_option_category_id = ref(0);
            const d_selected_inputs = ref({});
            const d_selected_class = ref({});
            const d_modal = ref({});
            const d_data_loaded = ref(false);
            const d_first_loaded = ref(true);
            const d_classes_active_status = ref({});
            const d_old = ref(props.p_old);
            const toastCount = ref(0);
            const d_warning = ref(null);
            const d_allow_application = ref(false); //申込可能かどうか
            const d_histories = ref({}); //申込履歴
            const d_user_school = ref(props.p_user_chool); //ユーザーが所属している学校
            const d_applied_timetable = ref({}); //時間割ごとの申込可能かどうかの配列
            const d_student_names = ref(props.p_student_names); //生徒の呼称

            const d_sorts = ref({}); //並べ替え設定

            //reference
            const r_classes = ref({});
            const r_modals = ref({});
            const r_mv = ref(null);

            //injection
            const i_api_url = inject('api_url');

            //オプションカテゴリーIDからの授業カテゴリーIDのインデックス
            const d_classes_category_index_by_option_category_id = ref({});

            //申込ボタン
            const d_application_button = ref(null);
            const r_application_button = (el) => {
                if (el) {
                    d_application_button.value = el;
                }
            }

            //ログインしていない場合のメッセージ
            const d_toast_auth = ref(null);

            const r_toast_auth = (el) => {
                if (el) {
                    d_toast_auth.value = el;
                }
            }



           //BeforeUpdate
            //-----------------------------------
            onBeforeUpdate(() => {

            })

            onUpdated(() => {


            })


            //初期化
            const init = async () => {




                //グループ機能の初期化
                ClassesGroups.init(i_api_url)

                //データの読み込み
                await get_active_data();



                //授業のインデックス用配列データを作成
                for(var schedule_id in d_active_classes.value){
                    var classes_schedule = d_active_classes.value[schedule_id]
                    for(var i in classes_schedule){
                        var timetable_id = classes_schedule[i]['timetable_id'];
                        var classes_group = classes_schedule[i]['classes'];
                        for(var l in classes_group){
                            var classes = classes_group[l];
                            var classes_id = classes.id;
                            d_classes_index.value[classes_id] = classes; //インデックス用の配列を作成
                        }
                    }
                }





                //選択した授業IDを格納する配列をセット、アクティブを判定する配列をセット
                for(let schedule_id in d_active_classes.value){

                    var classes_group = d_active_classes.value[schedule_id];
                    d_selected_classes.value[schedule_id] = Array();

                    for(let i in classes_group){
                        let timetable_id = classes_group[i]['timetable_id'];
                        var arr = {
                            'classes_id' : '',
                            'classes_category_id' : '',
                        }

                        d_selected_classes.value[schedule_id][timetable_id] = arr;
                    }
                }



                //申込可能人数と申し込み人数を初期化
                for(let i in d_classes_index.value){
                    let classes = d_classes_index.value[i]
                    let classes_id = classes.id;
                    let classes_categories = get_classes_categories(classes_id)

                    d_capacity.value[classes_id] = {};
                    d_application_number.value[classes_id] = {};



                    if(classes_categories){
                        for(let l in classes_categories){
                            let classes_category = classes_categories[l];
                            let classes_category_id = classes_category['id'];
                            let capacity = classes_category['capacity'];

                            //受付人数の設定
                            if(capacity){
                                d_capacity.value[classes_id][classes_category_id] = capacity;
                            }else{
                                d_capacity.value[classes_id][classes_category_id] = 0;
                            }
                            //申し込み人数の初期化
                            d_application_number.value[classes_id][classes_category_id] = 0;

                        }
                    }
                }



                //申し込み人数のカウント
                for(var classes_id in d_application_details.value){
                    let application_details = d_application_details.value[classes_id];
                    if(application_details.length){
                        for(var m in application_details){
                            let classes_category_id = application_details[m]['classes_category_id'];
                            if(classes_category_id in d_application_number.value[classes_id]){
                                var application_number = d_application_number.value[classes_id][classes_category_id];
                                d_application_number.value[classes_id][classes_category_id] = application_number + 1;
                            }
                        }
                    }
                }




                //各授業ごとのステータスの設定
                set_classes_status();






                //モーダルの初期化
                for(let d in d_classes.value){
                    for(let e in d){
                        if(e.has_category){
                            d_modal.value[e.id] = false;
                        }
                    }
                }

                //読み込み完了
                d_data_loaded.value = true;



                //スケジュールの選択
                nextTick(() => {
                    if(d_first_loaded){
                        if(Object.keys(d_schedules.value).length){
                            if(Object.keys(d_schedules.value).length > 1){ //スケジュールが２つ以上ある場合はクリック
                                if(props.p_selected_schedule_id){
                                    document.querySelector("#schedule_"+ props.p_selected_schedule_id + " a").click();
                                }else{
                                    if(document.querySelectorAll(".schedules li a").length){
                                        document.querySelector(".schedules li a").click();
                                    }
                                }
                            }else{
                                if(props.p_selected_schedule_id){
                                    select_schedule(props.p_selected_schedule_id, true);
                                }else{
                                    for(let i in d_schedules.value){
                                        let schedule = d_schedules.value[i];
                                        select_schedule(schedule['id'], true);
                                    }
                                }
                            }
                        }
                    }
                });







                //Toastの初期化
                d_warning.value = document.getElementById('classes_warning');

                //申し込み可能かどうかの判定
                judgeApplication();

            };

            //データの読み込み
            //---------------------------------------------
            const get_active_data = async () => {
                let url = i_api_url + "front/active/" + props.p_dev;


                await axios.get(url).then(response =>{
                    //フロントページ設定
                    d_message_front.value = response.data.front_contents['message_front']['value'];
                    d_message_front_logged_in.value = response.data.front_contents['message_front_logged_in']['value'];
                    d_message_front_logged_out.value = response.data.front_contents['message_front_logged_out']['value'];

                    //console.log('dddd')
                    //conole.log(response.data.front_contents)
                    //コンテンツ
                    d_front_contents.value = response.data.front_contents;
                    //授業
                    d_active_classes.value = response.data.classes;
                    //授業カテゴリー
                    d_classes_categories.value = response.data.classes_categories;
                    //時間割
                    d_timetables.value = response.data.timetables;
                    //オプション
                    d_options.value = response.data.options;
                    //オプション詳細
                    d_options_categories.value = response.data.options_categories;
                    //申し込み詳細
                    d_application_details.value = response.data.application_details;
                    //申し込み履歴の設定
                    d_histories.value = response.data.histories;
                    //グループ
                    d_classes_groups.value = response.data.groups;
                    //条件オプション
                    d_classes_conditions.value = response.data.classes_conditions;

                    //並べ替え設定
                    d_sorts.value = response.data.sorts;



                    //グループ機能へデータをセット
                    ClassesGroups.setData(d_active_classes.value, d_histories.value, d_classes_groups.value);


                    //スケジュール
                    //授業の無いスケジュールは除外
                    for(let i in response.data.schedules){
                        let schedule = response.data.schedules[i];
                        let schedule_id = schedule['id'];

                        let classes = response.data.classes[schedule_id];
                        let length = 0;

                        //データ型を調べる
                        if(isArray(classes)){ //配列の場合
                            length = classes.length;
                        }else if(isObject(classes)){ //オブジェクトの場合
                            length = Object.keys(classes).length
                        }

                        if(length){
                            d_schedules.value.push(schedule)
                        }
                    }

                    //IDごとの連想配列を生成
                    for(let i in d_schedules.value){
                        let schedule = d_schedules.value[i];
                        let schedule_id = schedule['id'];
                        d_schedules_index.value[schedule_id] = schedule;
                    }


                });
            };


            // Arrayの判定
            //---------------------------------------------
            const isArray = (item) => {
                return Object.prototype.toString.call(item) === '[object Array]';
            };

            // OBJECTの判定
            //---------------------------------------------
            const isObject = (item) => {
                return typeof item === 'object' && item !== null && !isArray(item);
            };

            //日付から文字列に変換する関数
            const  getStringFromDate = (date) => {

                let year_str = date.getFullYear();
                //月だけ+1すること
                let month_str = 1 + date.getMonth();
                let day_str = date.getDate();
                let hour_str = date.getHours();
                let minute_str = date.getMinutes();
                let second_str = date.getSeconds();


                let format_str = 'YYYY-MM-DD hh:mm:ss';
                format_str = format_str.replace(/YYYY/g, year_str);
                format_str = format_str.replace(/MM/g, month_str);
                format_str = format_str.replace(/DD/g, day_str);
                format_str = format_str.replace(/hh/g, hour_str);
                format_str = format_str.replace(/mm/g, minute_str);
                format_str = format_str.replace(/ss/g, second_str);

                return format_str;
            };



            //スケジュールの選択
            //--------------------------------------------------
            const select_schedule = (schedule_id, first_load) => {
                if(d_selected_schedule_id.value != schedule_id){
                    //選択していた授業の値をリセット
                    reset_selected_classes(d_selected_schedule_id.value);

                    //新しいスケジュールIDをセット
                    d_selected_schedule.value = d_schedules_index.value[schedule_id];
                    set_classes(schedule_id);
                    d_selected_schedule_id.value = d_selected_schedule.value['id'];



                    let tabs = document.querySelectorAll('.schedules li')
                    for (var i = 0; i < tabs.length; i++) {
                        let id = tabs[i].getAttribute('id').replace('schedule_','')
                        if(id == schedule_id){
                            tabs[i].classList.add('active');
                        }else{
                            tabs[i].classList.remove('active');
                        }

                    }

                    //MVの画像切替
                    r_mv.value.select_schedule(schedule_id);


                    nextTick(() => {
                        if(d_first_loaded.value){ //最初の読み込み時に授業の再選択

                            //Old値から授業の選択
                            if(d_old.value){
                                for (var timetable_id in d_old.value) {
                                    let select_classes_data = d_old.value[timetable_id];
                                    if(select_classes_data['classes_id'] && select_classes_data['classes_category_id']){
                                        let classes_id = select_classes_data['classes_id'];
                                        let classes_category_id = select_classes_data['classes_category_id'];
                                        select_classes(d_selected_schedule_id.value, timetable_id, classes_id, classes_category_id);
                                    }
                                }
                            }
                            d_first_loaded.value = false;

                        }else{ //そうでない場合はOld値をリセット
                            d_old.value = Array();
                            if(!first_load){ //最初のロードでない場合
                                reset_selected_classes(d_selected_schedule_id.value);
                                // 指定したセッションの削除
                                window.sessionStorage.removeItem(['selected_classs']);
                            }
                        }

                        //申し込み可能かどうかの判定
                        judgeApplication();
                    })
                }

                //アクティブを判定する配列をセット
                d_classes_active_status.value = {};
                for(let t in d_classes.value){
                    d_classes_active_status.value[t] = true;
                }

            };

            const set_classes = (schedule_id) => {



                d_classes.value = d_active_classes.value[schedule_id];


            };

            //授業の選択
            //--------------------------------------------------
            const select_classes = (...args) => {
                const [schedule_id, timetable_id, classes_id, classes_category_id] = args;



                //選択要素を指定
                let arrow_select = true;
                let target_section = document.querySelector('section.class[data-classes-id="' + classes_id + '"]');



                let status = d_status.value[classes_id];

                if(target_section){
                    //ステータスで受付可能かどうかのチェック
                    let check_classes_list = ["stop", "end", "full", "disabled", "coming_soon", "applied", "applied_timetable", "grouped"];
                    if(check_classes_list.indexOf(status) >= 0){
                        arrow_select = false;
                    }

                    if(arrow_select){

                        set_select_class_input(schedule_id, timetable_id, classes_id, classes_category_id);
                        set_select_classes_card(schedule_id, timetable_id, classes_id, classes_category_id);
                    }
                }

                //申し込み可能かどうかの判定
                judgeApplication();

            };

             //授業の選択解除
            const unselect_classes = (...args) => {
                const [schedule_id, timetable_id, classes_id, classes_category_id] = args;


                set_select_class_input(schedule_id, timetable_id, null, null);
                set_select_classes_card(schedule_id, timetable_id, null, null);

                //申し込み可能かどうかの判定
                judgeApplication();

            };

            //選択した授業の取得

            const get_selected_classes = (schedule_id, timetable_id) => {
                if(d_selected_classes.value[schedule_id][timetable_id]){
                    return d_selected_classes.value[schedule_id][timetable_id];
                }else{
                    return null;
                }
            };

            const reset_selected_classes = (schedule_id) => {
                if (schedule_id in d_selected_classes.value) {
                //if(in_array(d_selected_classes,schedule_id)){
                    for(let timetable_id in d_selected_classes.value[schedule_id]){
                        d_selected_classes.value[schedule_id][timetable_id] = {
                            'classes_id' : '',
                            'classes_category_id' : ''
                        }


                    }
                }
            };

            const clear_selected_classes = (schedule_id) => {

                for(let i in d_classes.value){
                    let timetable_id = d_classes.value[i]['timetable_id'];
                    let classes_group = d_classes.value[i]['classes'];

                    for(let i in classes_group){
                        let classes = classes_group[i]
                        let classes_id = classes['id'];
                        let ref_target_classes = r_classes.value[classes_id]; //要素のリファレンスを取得
                        if(ref_target_classes){
                            ref_target_classes.clear_select();
                        }
                    }
                }
            };


            //オプション開閉
            //--------------------------------------------------
            const open_classes_category = (classes_id) => {
                //let modal = ref['modal_' + classes_id][0];
                let modal = r_modals.value[classes_id]; //要素のリファレンスを取得


                modal.open_classes_category();
            };


            //オプションカテゴリーの選択
            const select_option_category = (...args) => {

                const [schedule_id, timetable_id, classes_id, option_category_id, classes_category_id] = args;

                set_select_class_input(schedule_id, timetable_id, classes_id, option_category_id, classes_category_id);
                set_select_classes_card(schedule_id, timetable_id, classes_id, option_category_id, classes_category_id);

                //申し込み可能かどうかの判定
                judgeApplication();


            };

            //選択した授業のセット
            //------------------------
            //inputの値のセット
            const set_select_class_input = (...args) => {
                const [schedule_id, timetable_id, classes_id, classes_category_id] = args;
                var new_classes_id = '';
                var new_classes_category_id = '';


                //すでに選択している授業の情報を取得
                if(schedule_id == d_selected_schedule_id.value){

                    let selected_classses_id = d_selected_classes.value[schedule_id][timetable_id]['classes_id'];
                    let selected_classes_category_id = d_selected_classes.value[schedule_id][timetable_id]['classes_category_id'];

                    var new_classes_category_id = 0;

                    if(classes_category_id){
                        new_classes_category_id = classes_category_id;
                    }

                    if(selected_classses_id == classes_id){ //すでに選択されていた場合は選択解除

                        new_classes_id = null;
                        new_classes_category_id = null;

                    }else{
                        new_classes_id = classes_id;
                        new_classes_category_id = classes_category_id;
                    }

                    //授業が選択されている場合は定員オーバーになっていないかチェック
                    if(new_classes_id !== null && new_classes_category_id !== null){
                        let capacity = d_capacity.value[new_classes_id][new_classes_category_id];
                        let application_number = d_application_number.value[classes_id][new_classes_category_id];
                        if(application_number >= capacity){
                            //定員オーバーしている場合は選択を外す
                            new_classes_id = null;
                            new_classes_category_id = null;
                        }
                    }


                    //新しく選択した授業IDを代入
                    d_selected_classes.value[schedule_id][timetable_id]['classes_id'] = new_classes_id;
                    d_selected_classes.value[schedule_id][timetable_id]['classes_category_id'] = new_classes_category_id;



                    //時間が重複している授業の選択を解除
                    let schedule = get_schedule(schedule_id)
                    let timetable = get_timetable(timetable_id)

                    let date_1 = {
                        start: new Date(schedule['date'].replace(/-/g,"/") + ' ' + timetable['start']),
                        end  : new Date(schedule['date'].replace(/-/g,"/") + ' ' + timetable['end'])
                    };

                    let select_cancel = false;


                    for(let t in d_selected_classes.value[schedule_id]){
                        let target_timetable = get_timetable(t)

                        let date_2 = {
                            start: new Date(schedule['date'].replace(/-/g,"/") + ' ' +  target_timetable['start']),
                            end  : new Date(schedule['date'].replace(/-/g,"/") + ' ' +  target_timetable['end'])
                        };

                        //時間割の時間が重複していないかチェック。重複している場合はその時間割の選択を外す
                        if(date_1.start < date_2.end && date_1.end > date_2.start && t != timetable_id){
                            if(d_selected_classes.value[schedule_id][t]['classes_id']){
                                select_cancel = true;
                            }

                            new_classes_id = ''
                            new_classes_category_id = '';


                            d_selected_classes.value[schedule_id][t]['classes_id'] = new_classes_id;
                            d_selected_classes.value[schedule_id][t]['classes_category_id'] = new_classes_category_id;

                            //アクティブを判定する配列をセット
                            d_classes_active_status.value[t] = false;
                        }else{
                            //アクティブを判定する配列をセット
                            d_classes_active_status.value[t] = true;
                        }

                        //グループの判定
                        ClassesGroups.setSelectClassesInput(selected_classses_id, new_classes_id)


                    }


                    //inputに値をセット
                    for(let t in d_selected_classes.value[schedule_id]){

                        let val_classes = d_selected_classes.value[schedule_id][t]['classes_id'];
                        let val_option_category = d_selected_classes.value[schedule_id][t]['classes_category_id'];
                        let input_classes = document.querySelector('input[type="hidden"][name="selected_classes[' + t + '][classes_id]"]');
                        let input_option_category = document.querySelector('input[type="hidden"][name="selected_classes[' + t + '][classes_category_id]"]');

                        input_classes.value = val_classes;
                        input_option_category.value = val_option_category

                    }

                    //解除された授業がある場合はToastで警告を表示
                    if(select_cancel){
                        var option = {
                            autohide : true,
                            delay : 10000
                        }
                        var warning = new Toast(d_warning.value, option);
                        warning.show();
                    }

                }
            };

            //カードの選択
            const set_select_classes_card = (...args) => {
                const [schedule_id, timetable_id, classes_id, classes_category_id] = args;
                let schedule = get_schedule(schedule_id)





                //選択された授業のグループIDの取得
                //let selected_classes_group_id = d_classes_index.value[classes_id]['group_id'];

                //一度全授業の選択を解除する
                clear_selected_classes(schedule_id);

                //選択された授業をアクティブにする。
                for(let timetable_id_1 in d_selected_classes.value[schedule_id]){
                    var selected_classes = d_selected_classes.value[schedule_id][timetable_id_1]

                    if(selected_classes['classes_id'] > 0 && selected_classes['classes_category_id'] >= 0 ){

                        let target_classes_id = selected_classes['classes_id']
                        let target_classes_category_id = selected_classes['classes_category_id']
                        let ref_target_classes = r_classes.value[target_classes_id]; //要素のリファレンスを取得

                        if(ref_target_classes){
                            ref_target_classes.selected(target_classes_category_id);
                        }
                    }
                }


                //他の授業は同じ時間割の授業と時間割が違っても時間が重複している授業はデアクティブにする
                //同じグループに属している授業が選択されている場合はデアクティブにする。

                for(let i in d_classes.value){
                    let timetable_id = d_classes.value[i]['timetable_id'];
                    let classes_group = d_classes.value[i]['classes'];
                    for(let i in classes_group){
                        let deactive = false;
                        let classes = classes_group[i]
                        let target_classes_id = classes['id'];
                        let target_timetable_id = classes['timetable_id']
                        let target_status = d_status.value[target_classes_id]
                        let target_timetable = get_timetable(target_timetable_id) //時間割を取得;
                        let ref_target_classes_2 = r_classes.value[target_classes_id]; //要素のリファレンスを取得


                        //現在選択された授業でないか判定。選択された授業でなければ次へ進む
                        let selected_classses = d_selected_classes.value[schedule_id][target_timetable_id]['classes_id'];
                        if(!selected_classses || selected_classses != target_classes_id){

                            for(let timetable_id_2 in d_selected_classes.value[schedule_id]){
                                var selected_classes_2 = d_selected_classes.value[schedule_id][timetable_id_2]

                                //なにか授業が選択されている場合
                                if(selected_classes_2['classes_id'] > 0 && selected_classes_2['classes_category_id'] >= 0 ){
                                    let selected_classes_id = selected_classes_2['classes_id']

                                    let classes_2 = d_classes_index.value[selected_classes_id]

                                    let selected_timetable_id = classes_2['timetable_id'];
                                    let selected_timetable = get_timetable(selected_timetable_id);



                                    //同じ時間割の場合はデアクティブにする
                                    if(target_timetable_id == selected_timetable_id){
                                        deactive = true;
                                    }else{
                                        //違う時間割の授業でも時間が重複している授業は選択不可にする。
                                        if(check_doubling_time(schedule['date'], target_timetable['start'], target_timetable['end'], selected_timetable['start'], selected_timetable['end'])){
                                            deactive = true;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                        //グループの判定
                        if(!deactive){
                            deactive = ClassesGroups.setSelectClassesCard(target_classes_id)
                        }

                        if(deactive){
                            if(ref_target_classes_2){
                                ref_target_classes_2.deactive();
                            }
                        }
                    }
                }
            };

            //カードの再選択
            const reselect_classes_card = () => {

                for(let schedule_id in d_selected_classes.value){
                    if(d_selected_classes.value[schedule_id]){
                        let classes_group = d_selected_classes.value[schedule_id]
                        for(let timetable_id in classes_group){
                            if(classes_group[timetable_id]){
                                let classes = classes_group[timetable_id]

                                if(classes['classes_id'] != ''){
                                    const selected_classes_id = classes['classes_id'];
                                    const selected_classes_category_id = classes['classes_category_id'];

                                    //選択要素を指定
                                    let target_classes = document.querySelectorAll('section.class[data-classes-schedule-id="' + schedule_id + '"][data-classes-timetable-id="' + timetable_id + '"]');

                                    target_classes.forEach((target_class) => {
                                        let target_classes_id = target_class.dataset.classesId;
                                        //let ref_target_classes = ref['classes_' + target_classes_id];
                                        let ref_target_classes = r_classes.value[target_classes_id]; //要素のリファレンスを取得


                                        if(selected_classes_id == target_classes_id){
                                            ref_target_classes.selected(selected_classes_category_id);
                                        }else{
                                            ref_target_classes.deactive();
                                        }
                                    })
                                }
                            }
                        }
                    }
                }
            };


            //スケジュール
            //---------------------------------------------
            const get_all_schedules = () => {
                let url = "/api/schedules/get/all";
                axios.get(url).then(response =>{
                    d_schedules.value = response.data;
                });
            };

            const get_schedules = (all) => {
                let url = "/api/schedules/get";
                axios.get(url).then(response =>{
                    d_schedules.value = response.data;
                });
            };

            const get_active_schedules = async () => {
                let url = "/api/schedules/get/active";
                await axios.get(url).then(response =>{
                    d_schedules.value = response.data;
                });
            };


            const get_selected_schedule = () => {
                return d_selected_schedule.value;
            };

            const get_schedule = (schedule_id) => {
                if(d_schedules_index.value[schedule_id]){
                    return d_schedules_index.value[schedule_id];
                }else{
                    return null;
                }
            };

            // 時間割関係
            //---------------------------------------------
            const get_all_timetables = async () => {
                let url = '/api/timetable/get/all';
                await axios.get(url).then(response =>{
                    for (let i in response.data) {
                        var id = response.data[i]['id'];
                        d_timetables.value[id] = response.data[i];
                    }
                });
            };

            const exist_timetable = (id) => {
                if(id in d_timetables.value){
                    return true;
                }else{
                    return false;
                }
            };



            const get_timetable = (id) => {
                if(d_timetables.value[id]){
                    return d_timetables.value[id];
                }else{
                    return null;
                }
            };

            const get_timetable_id = (id) => {
                if(id in d_timetables.value){
                    return d_timetables.value[id].id;
                }
            };

            const get_timetable_title = (id) => {
                if(d_timetables.value[id]){
                    return d_timetables.value[id].title;
                }else{
                    return null;
                }
            };

            const get_timetable_start = (id) => {
                if(d_timetables.value[id]){
                    var today = new Date();
                    var year = today.getFullYear();
                    var month = ("0" + (today.getMonth() + 1)).slice(-2);
                    var day = ("0" + today.getDate()).slice(-2);
                    var date = year + "-" + month + "-" + day + " " + d_timetables.value[id].start

                    var m = moment(date);

                    return m.format("HH:mm");
                }

            };

            const get_timetable_end = (id) => {
                if(d_timetables.value[id]){
                    var today = new Date();
                    var year = today.getFullYear();
                    var month = ("0" + (today.getMonth() + 1)).slice(-2);
                    var day = ("0" + today.getDate()).slice(-2);
                    var date = year + "-" + month + "-" + day + " " + d_timetables.value[id].end;
                    var m = moment(date);

                    return m.format("HH:mm");
                }

            };


            //授業関係
            //---------------------------------------------
            const get_classes = async (schedule_id) => {
                let url = "/api/classes/get/" + schedule_id;
                await axios.get(url).then(response =>{
                    d_active_classes.value[schedule_id.toString()] = response.data;
                    for(let i in response.data){
                        let classes = response.data[i];
                        let id = classes.id;
                        d_classes_index.value[id] = classes;
                    }
                });
            };

            const get_classes_image_path = () => {
                return p_image_path_classes;
            };







            //ステータスの設定
            const set_classes_status = () => {



                let now = moment();
                let now_date = now.toDate();




                for(let classes_id in d_classes_index.value){
                    let classes = d_classes_index.value[classes_id];
                    let status = '';

                    //日付がNULLの場合は設定する。
                    if(!classes.release_date){
                        classes.release_date = moment().add(-10, "y").format("YYYY-MM-DD HH:mm:ss")

                    }
                    if(!classes.close_date){
                        classes.close_date = moment().add(10, "y").format("YYYY-MM-DD HH:mm:ss")
                    }
                    if(!classes.start_date){
                        classes.start_date = moment().add(-10, "y").format("YYYY-MM-DD HH:mm:ss")
                    }
                    if(!classes.end_date){
                        classes.end_date = moment().add(10, "y").format("YYYY-MM-DD HH:mm:ss")
                    }


                    let release_date = new Date(classes.release_date.replace(/-/g,"/"));
                    let close_date = new Date(classes.close_date.replace(/-/g,"/"));
                    let start_date = new Date(classes.start_date.replace(/-/g,"/"));
                    let end_date = new Date(classes.end_date.replace(/-/g,"/"));


                    let schedule_id = classes.schedule_id;
                    let timetable_id = classes.timetable_id;


                    //授業の開始時間、終了時間
                    let classes_timetable = d_timetables.value[timetable_id];
                    let classes_timetable_start = classes_timetable['start'];
                    let classes_timetable_end = classes_timetable['end'];

                    //申込み数、定員、授業カテゴリー
                    let application_numbers = d_application_number.value[classes_id]
                    let capacities = d_capacity.value[classes_id];
                    let classes_categories = d_classes_categories.value[classes_id];



                    //過去に申し込み済みかどうかを判定
                    if(status == ''){
                        if(get_applied(classes_id)){
                            status = 'applied';
                        }
                    }



                    if(!props.p_dev){ //通常モードでのプレビューの場合
                        //募集停止しているか判定
                        if(status == ''){
                            if(classes['stop']){
                                status = 'stop'
                            }else{
                                if(start_date > now_date){
                                    status = 'coming_soon';
                                }else if (end_date < now_date){
                                    status = 'end';
                                }
                            }
                        }
                    }






                    //ログインしている場合、ユーザーが対象の学校に所属しているかどうか判定
                    if(status == ''){
                        if(props.p_auth){
                            if(!get_applicable_school(schedule_id)){
                                status = 'not_applicable_school';
                            }
                        }
                    }

                    //受付開始前か終了後でなければ定員に達していないかどうか調べる
                    if(status == ''){

                        var full = true;
                        for(let i in classes_categories){
                            let classes_category = classes_categories[i]


                            let classes_category_id = classes_category['id'];
                            if(classes_category_id in application_numbers && classes_category_id in capacities){
                                let application_number = application_numbers[classes_category_id]
                                let capacity = capacities[classes_category_id]

                                if(application_number < capacity){
                                    full = false;
                                    break;
                                }
                            }
                        }

                        //定員に満たしている場合
                        if(full){
                            status = 'full';
                        }else{
                            status = 'arrival';
                        }
                    }

                    //同じ時間割に申込済みの授業がある場合は申込み不可にする。
                    //違う時間割でも申込済みの授業と時間が重複している場合は申込み不可にする。
                    if(status == 'arrival'){
                        if(get_applied_timetable_time(schedule_id, classes_timetable_start, classes_timetable_end)){
                            status = 'applied_timetable';
                        }
                    }

                    //同じグループの授業が申し込まれていないか、選択されていないか判定する。

                    if(status == 'arrival'){

                        let status_group = ClassesGroups.getClassesStatus(classes_id)
                        if(status_group){
                            status = status_group;
                        }


                    }


                    //設定された条件オプションに満たしているかどうかを判定する。
                    if(status == 'arrival'){
                        if (classes_id in d_classes_conditions.value) {
                            let condition_status = ClassesConditions.check_conditions(classes, application_numbers, capacities, classes_categories, d_classes_conditions.value[classes_id])
                            if(condition_status){
                                status = condition_status;
                            }
                        }
                    }

                    d_status.value[classes_id] = status;
                }
            };

            const get_classes_status = (classes_id) => {
                return d_status.value[classes_id]
            };



            //授業カテゴリー
            //---------------------------------------------
            const get_classes_categories = ($classes_id) => {
                if(d_classes_categories.value[$classes_id]){
                    return d_classes_categories.value[$classes_id];
                }else{
                    return null
                }
            };



            //申し込みデータ
            //---------------------------------------------
            //申し込みデータの読み込み
            const get_application_details = ($classes_id) => {
                if(d_application_details.value[$classes_id]){
                    return d_application_details.value[$classes_id];
                }else{
                    return null
                }
            };

            //オプション関係
            //---------------------------------------------
            const get_options = async () => {
                for(let schedule_id in d_active_classes.value){
                    let classes_group = d_active_classes.value[schedule_id];
                    for(let timetable_id in classes_group){
                        let classes = classes_group[timetable_id];
                        for(let i in classes){
                            let option_id = classes[i]['option_id']
                            if(option_id){
                                if(!d_options.value[option_id]){
                                    let url = "/api/options/get/" + option_id;
                                    await axios.get(url).then(response => {
                                        d_options.value[option_id] = response.data;
                                    });
                                }
                            }
                        }
                    }
                }
            };

            const get_option = (option_id) => {
                if(d_options.value[option_id]){
                    return d_options.value[option_id]
                }else{
                    null;
                }
            };

            const get_options_categories = async () => {
                for(let option_id in d_options.value){
                    if(!d_options_categories.value[option_id]){
                        let url = "/api/options_categories/get/" + option_id;
                        await axios.get(url).then(response =>{
                            d_options_categories.value[option_id] = response.data;
                        });
                    }
                }
            };

            const get_option_category = (option_id) => {
                if(d_options_categories.value[option_id]){
                    return d_options_categories.value[option_id];
                }else{
                    return null;
                }

            };

            //過去に申し込み履歴があるかチェック
            const get_applied = (classes_id) => {
                let applied = false;

                for(let history_classes_id in d_histories.value) {
                    if(history_classes_id == classes_id){
                        applied =  true;
                        break;
                    }
                }

                return applied;
            };


            //申し込んだ授業の時間と同一の時間の判定、あれば申し込み不可にする。
            const get_applied_timetable_time = (schedule_id, start, end) => {
                let applied = false;
                let schedule = d_schedules_index.value[schedule_id];
                let date = schedule['date'];


                for(let history_classes_id in d_histories.value) {
                    let history_schedule_id = d_histories.value[history_classes_id]['schedule_id']
                    //選択しているスケジュールで申し込み履歴のある授業がある場合は時間を比較する
                    if(history_schedule_id == schedule_id){
                        let history_timetable_id = d_histories.value[history_classes_id]['timetable_id'];
                        let history_timetable = d_timetables.value[history_timetable_id];
                        let history_start = history_timetable['start'];
                        let history_end = history_timetable['end'];

                        if(check_doubling_time(date, history_start, history_end, start, end)){
                            applied = true;
                            break;
                        }
                    }
                }
                return applied;

            }

            //対象の学校に所属しているか判定
            const get_applicable_school = (schedule_id) => {


                let applicable = true;

                //スケジュールから対象の学校を取得
                let schedule_schools = get_schedule_school(schedule_id);


                if(schedule_schools){
                    let arr_schools = [];
                    if(schedule_schools.indexOf(',') >= 0){
                        let arr_schools_temp = schedule_schools.split(',')

                        for(let school_num in arr_schools_temp){
                            arr_schools.push(parseInt(arr_schools_temp[school_num]))
                        }
                    }else{
                        arr_schools.push(parseInt(schedule_schools));
                    }

                    if(arr_schools.indexOf(props.p_user_school) < 0){
                        applicable = false;
                    }
                }


                return applicable;
            };


            //スケジュールIDから対象の学校を取得
            const get_schedule_school = (schedule_id) => {

                let schools = '';
                if(d_schedules_index.value[schedule_id]){
                    let schedule = d_schedules_index.value[schedule_id];
                    schools = schedule['school'];
                }
                return schools;
            }




            //サイトの動作
            //---------------------------------------------
            //授業の選択有効化
            const activeClass = (card) => {
                card.classList.add("active");
                card.classList.remove("deactive");
            };

            //授業の選択無効化
            const deactiveClass = (card) => {
                card.classList.add("deactive");
                card.classList.remove("active");
            };

            //授業の通常化
            const normalityClass = (card) => {
                card.classList.remove("active");
                card.classList.remove("deactive");
            };

            //授業の選択不可
            const disableClass = (card) => {
                card.classList.add("disable")
            };

            //選択された授業の整理
            const selectedClass = (id,schedule,timetable) => {
                window.selectedClass[schedule][timetable] = id;
                //window.selectedClass.get(schedule).set(timetable,id)
            };

            const unSelectedClass = (schedule,timetable) => {
                window.selectedClass[schedule][timetable] = '';
                //window.selectedClass.get(schedule).set(timetable,'')
            };

            //申し込み可能かどうかを判定
            const judgeApplication = () => {

                let allow = false;
                for(let schedule_id in d_selected_classes.value){
                    for(let timetable_id in d_selected_classes.value[schedule_id]){
                        let arr = d_selected_classes.value[schedule_id][timetable_id]
                        //授業が選択されている場合はtrueを返す
                        if(d_selected_classes.value[schedule_id][timetable_id]['classes_id']){
                            allow = true;
                            break;
                        }
                    }
                }

                d_allow_application.value = allow;
                d_application_button.value.judgeApplication(d_allow_application.value)
            };

            //ログインしていない場合にメッセージを表示
            const not_auth = () => {
                d_toast_auth.value.not_auth();
            };

            //時間が重複していないか比較
            const check_doubling_time = (date, time_1_start, time_1_end, time_2_start, time_2_end) => {

                let date_1 = {
                    'start': new Date(date.replace(/-/g,"/") + ' ' +  time_1_start),
                    'end'  : new Date(date.replace(/-/g,"/") + ' ' +  time_1_end)
                }
                let date_2 = {
                    'start': new Date(date.replace(/-/g,"/") + ' ' +  time_2_start),
                    'end'  : new Date(date.replace(/-/g,"/") + ' ' +  time_2_end)
                }
                if(date_1['start'] < date_2['end'] && date_1['end'] > date_2['start']){
                    return true;

                }else{
                    return false;
                }
            };




            //Mounted
            //-----------------------------------
            onMounted(async () => {
                try {

                    await init();
                }catch (err) {
                    error.value = err.message
                }


            });


            return {
                d_front_contents,
                d_message_front,
                d_message_front_logged_in,
                d_message_front_logged_out,
                d_schedules,
                d_schedules_index,
                d_active_classes,
                d_classes,
                d_classes_index,
                d_classes_categories,
                d_classes_conditions,
                d_classes_groups,
                d_timetables,
                d_options,
                d_options_categories,
                d_application_details,
                d_arr_options_categories,
                d_selected_schedule,
                d_selected_schedule_id,
                d_capacity,
                d_application_number,
                d_status,
                d_image_path_schedules,
                d_image_path_classes,
                d_domain,
                d_selected_classes,
                d_select_classes_id,
                d_select_option_id,
                d_select_option_category_id,
                d_selected_inputs,
                d_selected_class,
                d_modal,
                d_data_loaded,
                d_first_loaded,
                d_classes_active_status,
                d_old,
                d_histories,
                d_warning,
                d_toast_auth,
                d_applied_timetable,
                d_application_button,
                d_user_school,
                d_student_names,
                d_image_path_schedules,
                d_sorts,
                r_classes,
                r_modals,
                r_mv,
                r_application_button,
                r_toast_auth,
                toastCount,
                i_api_url,
                init,
                get_active_data,
                isArray,
                isObject,
                select_schedule,
                set_classes,
                select_classes,
                unselect_classes,
                get_selected_classes,
                reset_selected_classes,
                clear_selected_classes,
                open_classes_category,
                select_option_category,
                set_select_class_input,
                set_select_classes_card,
                reselect_classes_card,
                get_all_schedules,
                get_schedules,
                get_active_schedules,
                get_selected_schedule,
                get_schedule,
                get_all_timetables,
                exist_timetable,
                get_timetable,
                get_timetable_id,
                get_timetable_title,
                get_timetable_start,
                get_timetable_end,
                get_classes,
                get_classes_image_path,
                set_classes_status,
                get_classes_status,
                get_classes_categories,
                get_application_details,
                get_options,
                get_option,
                get_options_categories,
                get_option_category,
                get_applied,
                get_applied_timetable_time,
                get_applicable_school,
                get_schedule_school,
                activeClass,
                deactiveClass,
                normalityClass,
                disableClass,
                selectedClass,
                unSelectedClass,
                judgeApplication,
                not_auth,
                check_doubling_time
            }
        },
        methods: {
            moment: function (date) {
                return moment(date).format('YYYY年M月D日');
            },
            moment_schedule: function (date) {

                return moment(date).format('YYYY年M月D日');
            },
            moment_start_date: function (date) {
                return moment(date).format('M月D日 kk:mm');
            },
            moment_timetable: function (date) {
                return moment(date).format('kk:mm');
            },
        },


        computed: {

        },
    })

</script>

<style scoped lang="scss">
    @import "../../../scss/modules/common/variables.scss";
    $border-timetable: #{$color-secondary} 1px solid;
    $border-radius-timetable: 0px;
    $border-radius: 0px;
    $bg-timetable: #eee;





    //スケジュール一覧
    //タブボタン
    .schedules {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        border-bottom: none;
        justify-content: stretch;

        li {
            width: 50%;
            align-self: stretch;
            @media #{$bp-tab} {
                width: 33.3%;
            }

            &.nav-item {
                border-color: #dee2e6;
                border-width: 1px;
                border-style: solid;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                align-items: center;
                display: flex;
            }

            a {
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border: none;
                text-align: center;
                height: 100%;
                color: $color-primary !important;
                img {
                    width: 20%;
                    margin-bottom: 0.5em;
                }
                p {
                    line-height: 1em;
                }
                .nav-link {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
            &.active {
                a {
                    color: $color-white !important;
                    background-color: $color-primary !important;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }
        }
    }


    //授業一覧
    article {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .classes_header {
            width: 100%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            img {
            }
            h2 {
                color: $color-secondary;
                margin: 1.5em 0 1em;
                font-weight: bold;
                font-size: 2em;
            }
        }
        .classes_group {
            width: 100%;
            margin-bottom: 60px;


            .classes {
                z-index: 10;
                border-radius: #{$border-radius-timetable};
                margin: 0.2em 0;
                border-left: #{$color-secondary} 1px solid;
                box-shadow : 0px -px 0 1px #{$color-secondary};
                //display: flex;
                //flex-wrap: wrap;
                //flex-direction: column;
                //justify-content: space-between;
                h3 {
                    font-size: 1.5em;
                    color: $color-white;
                    background-color: #{$color-secondary};
                    border-radius: 0;
                }
                .inner{
                    section{
                        width: 100%;
                        align-items: stretch;
                        margin-bottom: 1px;
                        border-right: #{$color-secondary} 1px solid;
                        border-bottom: #{$color-secondary} 1px solid;


                        @media #{$bp-tab} {
                            width: calc(50% - 1px);

                            &:nth-of-type(2n-1){
                                border-right: #{$color-secondary} 1px solid;
                            }
                        }
                        @media #{$bp-pc} {
                            width: 33.3%;

                        }
                    }
                }
            }
        }
    }
</style>
