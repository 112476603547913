//Axios
import axios from 'axios';
import axiosJsonpAdapter from 'axios-jsonp';

axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default class Common {

    constructor(){
        this.init();
    }

    init() {
        //郵便番号の入力項目がある場合は自動検索を実装
        let zip_code = document.querySelector('input[name="zip_code"]');
        if (zip_code) {
            //郵便番号検索を実装
            zip_code.addEventListener("input", (event) => {
                this.zipSearch();
            })
        }

        this.modalDisable();

    }

    //郵便番号検索
    //-----------------------------------
    zipSearch() {

        let zip_code = document.querySelector('input[name="zip_code"]').value;
        let num_zip_code = zip_code.toString();

        if(num_zip_code.match(/^\d{7}$/)){
            let url = 'https://zipcloud.ibsnet.co.jp/api/search'

            const res = axios.get(url, {
                adapter: axiosJsonpAdapter,
                params: {
                    zipcode: num_zip_code
                }
            }).then(response => {
                if ('results' in response.data && response.data.results && response.data.results.length) {

                    let str_pref = response.data.results[0].address1; //都道府県
                    let str_city = response.data.results[0].address2; //市町村
                    let str_address = response.data.results[0].address3; //住所



                    let pref = document.querySelector('select[name="pref"]');
                    let city = document.querySelector('input[name="city"]');
                    let address = document.querySelector('input[name="address"]');

                    //都道府県のキーを探す
                    if (pref && str_pref) {
                        const pref_key = Object.keys(arr_pref).filter((key) => {
                            return arr_pref[key] === str_pref
                        });

                        console.log(pref_key)


                        if (pref_key) {
                            pref.value = pref_key ;
                        }
                    }

                    //市町村
                    if (city) {
                        city.value = str_city;
                    }

                    //住所
                    if (address) {
                        address.value = str_address ;
                    }
                }
            });
        }
    }

    //BootStrapのモーダルの動作.ボタンを押したら機能を無効にする
    modalDisable() {
        let modals = document.querySelectorAll('.modal');
        modals = Array.from( modals ) ;
        modals.forEach(function(modal) {
            let btnOK = modal.querySelectorAll('button[type=submit]')[0]
            let btnClose_1 = modal.querySelectorAll('button.btn-secondary')[0]
            let btnClose_2 = modal.querySelectorAll('button.btn-close')[0]
            let modal_footer = modal.querySelectorAll('.modal-footer')[0]

            if (btnOK) {
                btnOK.addEventListener('click', function (event) {
                    event.target.classList.add('disabled');
                    modal_footer.classList.add('ok-clicked');
                    btnClose_1.classList.add('disabled');
                    btnClose_2.classList.add('disabled');
                    btnClose_1.disabled = true;
                    btnClose_2.disabled = true;

                    // id属性で要素を取得
                    let modal_back_drop = document.querySelectorAll('.modal-backdrop')[0]

                    // 新しいHTML要素を作成
                    let disable_cover = document.createElement('div');
                    disable_cover.classList.add('modal-disable-cover');

                    let loader = document.createElement('div');
                    loader.classList.add('loader');
                    loader.innerHTML = 'Loading...';

                    // 指定した要素の中の末尾に挿入
                    modal_back_drop.parentNode.insertBefore(disable_cover, modal_back_drop);

                    let disable_cover_2 = document.querySelectorAll('.modal-disable-cover')[0]
                    disable_cover_2.appendChild(loader)
                })
            }
        });
    }
}


