//APIのベースURL
var api_url = '';


//グループ
var active_classes = {}; //読み込まれた授業
var grouped_classes = {}; //グループ化された授業
var application_histories = {};  //申込み履歴からグループを取得
var history_groups = {};  //申込み履歴からグループを取得
var groups = {};

var selected_grouped_classes = {}

function init(api){
    //API URLの設定
    api_url = api;
}

async function setData(_classes, _histories, _groups){

    setClasses(_classes)
    setHistories(_histories)
    setGroups(_groups)
}

//授業のセット
async function setClasses(all_classes){
    for(let schedule_id in all_classes){
        for(let i in all_classes[schedule_id]){
            let classes_data = all_classes[schedule_id][i];
            let timetable_id = classes_data['timetable_id'];
            let classes_group = classes_data['classes'];

            for(let l in classes_group){
                let classes = classes_group[l];
                let classes_id = classes['id'];
                let group_id = classes['group_id'];
                active_classes[classes_id] = classes;

                //グループが設定されている場合
                if(group_id){
                    if (group_id in grouped_classes === false) {
                        grouped_classes[group_id] = [];
                    }
                    grouped_classes[group_id].push(classes_id)
                }
            }
        }
    }

}

//申込み履歴からグループの取得
async function setHistories(histories){
    application_histories = histories

    for(let classes_id in histories){
        let application_detail = histories[classes_id];
        let classes = active_classes[classes_id]
        let group_id = classes['group_id'];
        if(group_id){
            if(group_id in history_groups === false){
                history_groups[group_id] = [];
            }
            history_groups[group_id].push(classes)
        }

    }
}

function setGroups(_groups){
    for(let i in _groups){
        let group_id = _groups[i]['id']
        groups[group_id] = _groups[i];

        selected_grouped_classes[group_id] = null;
    }
}



//グループを取得
async function getGroups(group_id){
    /*
    for(let group_id in groups){
        let url = api_url + "group/get/" + group_id;
        await axios.get(url).then(response =>{
            groups[group_id] = response.data.group
        });
    }
    */
}

//ステータスの判定
function getClassesStatus(classes_id){

    let status = null;

    //グループに属しているか
    let classes = active_classes[classes_id];
    let group_id = active_classes[classes_id]['group_id'];
    let schedule_id = active_classes[classes_id]['schedule_id'];



    if(group_id){ //グループに属している場合は過去の申込み履歴から重複をチェック
        //グループの読み込み
        let group = groups[group_id];
        //重複が禁止されているか。
        let no_duplication = group['no_duplication']
        //異なるスケジュールで有効になっているか
        let other_schedules = group['other_schedules']

        if(no_duplication){ //重複禁止の場合
            for(let i in history_groups[group_id]){ //過去の申し込みし歴のうち、グループに属している授業を読み込んで判定
                let history_classes = history_groups[group_id][i];
                let history_classes_id = history_classes['id'];
                let history_schedule_id = history_classes['schedule_id'];
                if(other_schedules){ //他のスケジュールを含む場合は申込み不可にする。
                    status = 'grouped';
                    break;
                }else{
                    if(schedule_id == history_schedule_id){
                        status = 'grouped';
                        break;
                    }
                }
            }
        }
    }

    return status;
}

//授業が選択された時
function setSelectClassesInput(old_selected_classes_id, new_selected_classes_id){
    let old_classes = null;
    let new_classes = null;
    let old_group_id = null;
    let new_group_id = null;
    if(old_selected_classes_id){
        old_classes = active_classes[old_selected_classes_id];
        old_group_id = old_classes['group_id'];
    }

    if(new_selected_classes_id){ //新しく授業が選択された場合
        new_classes = active_classes[new_selected_classes_id];
        new_group_id = new_classes['group_id'];
    }

    if(old_selected_classes_id){ //既に授業が選択されていた場合
        if(new_selected_classes_id){ //新しく選択された場合
            if(old_group_id == new_group_id){
                selected_grouped_classes[old_group_id] = new_selected_classes_id;
            }
        }else{ //選択が解除された場合
            if(old_group_id){
                selected_grouped_classes[old_group_id] = null;
            }
        }
    }else{ //授業が選択されていなかった場合
        if(new_selected_classes_id){ //新しく授業が選択された場合
            if(new_group_id){
                selected_grouped_classes[new_group_id] = new_selected_classes_id;
            }
        }
    }
}

//デアクティブの判定
function setSelectClassesCard(classes_id){

    let deactive = false;
    if(classes_id){
        let classes = active_classes[classes_id]
        let group_id = classes['group_id'];


        if(group_id){
            let group = groups[group_id];
            let no_duplication = group['no_duplication']
            if(no_duplication){
                if(selected_grouped_classes[group_id]){
                    if(selected_grouped_classes[group_id] != classes_id){
                        deactive = true;
                    }
                }
            }
        }
    }

    return deactive;
}









// 関数をエクスポートします。
export default {
    api_url,
    groups,
    active_classes,
    grouped_classes,
    application_histories,
    history_groups,
    selected_grouped_classes,
    init,
    setData,
    setClasses,
    setHistories,
    setGroups,
    getGroups,
    getClassesStatus,
    setSelectClassesInput,
    setSelectClassesCard
};
