<template>
    <div :class="[{card : true, selected: d_selected, deactive: d_deactive, disabled: d_disabled, authed : d_allow_application},p_status]">
        <a :data-classes-id="d_class.id" :data-classes-schedule-id="d_schedule.id" :data-classes-timetable-id="d_timetable.id" :data-classes-schedule="d_schedule.date" :data-classes-timetable="d_timetable.id" href="#" v-on:click.prevent="select_classes()" >
            <!--<img class="card-img-top" :src="p_image_path_classes + '/' + d_class.image" :alt="d_class.title">-->
            <div class="card-header">
                <h4 class="card-title">{{d_class.title}}</h4>
            </div>
            <div class="card-body">
                <div class="">
                    <div v-if="d_show_option" class="alert alert-primary" role="alert">
                        {{ d_option_title }} : {{ d_selected_classes_category_title }}
                    </div>
                    <div class="card-text">
                        <p v-if="d_class.content !== null" class="card-content" style="white-space:pre-wrap; word-wrap:break-word;">{{d_class.content}}</p>
                    </div>

                    <div v-if="d_class.note !== null && p_auth && p_status == 'arrival'" ref="r_note" :id="'collapse_' + d_class.id">
                        <p class="fw-bold mb-0">持参物と受講上の注意</p>
                        <p class="m-0" style="white-space:pre-wrap; word-wrap:break-word;">{{d_class.note}}</p>
                    </div>
                </div>
            </div>
        </a>
        <p v-if="p_status == 'coming_soon'" class="status"><span>{{ moment_start_date(d_class.start_date) }} より受付開始</span></p>
        <p v-else-if="p_status == 'end'" class="status"><span>受付は終了しました</span></p>
        <p v-else-if="p_status == 'stop'" class="status"><span>受付は終了しました</span></p>
        <p v-else-if="p_status == 'full'" class="status"><span>定員に達しました</span></p>
        <p v-else-if="p_status == 'applied'" class="status"><span>お申込済</span></p>
        <p v-else-if="p_status == 'applied_timetable'" class="status"><span>同一時間でお申込済</span></p>
        <p v-else-if="p_status == 'not_applicable_school'" class="status"><span>{{ d_applicable_school_names }}限定</span></p>
        <p v-else-if="p_status == 'grouped'" class="status"><span>同一授業をお申込済</span></p>
    </div>
</template>


<script>
import { defineComponent, computed, ref, reactive, onMounted, onUpdated } from "vue";
import moment from "moment";
import { Collapse } from "bootstrap";

export default defineComponent({
    name: "FrontClassesCard",

    props: {
        p_dev : {
            type : Number,
            default : 0
        },
        p_auth : {
            type : Number,
            default :0
        },
        p_verified : {
            type : Number,
            default : 0
        },
        p_class : {
            type : Object,
            default :null
        },
        p_selected : {
            type : Boolean,
            default : false
        },
        p_id : {
            type : Number,
            default : 0
        },
        p_option_id : {
            type : Number,
            default : 0
        },
        p_classes_category_id : {
            type : Number,
            default : 0
        },
        p_status : {
            type : String,
            default : ""
        },
        p_title : {
            type : String,
            default : ""
        },
        p_group_id : {
            type : Number,
            default : 0
        },
        p_image : {
            type : String,
            default : ""
        },
        p_content : {
            type : String,
            default : ""
        },
        p_note : {
            type : String,
            default : ""
        },
        p_schedule : {
            type : Object,
            default : null
        },
        p_timetable : {
            type : Object,
            default : null
        },
        p_option : {
            type : undefined,
            default : null
        },
        p_option_categories : {
            type : undefined,
            default : null
        },
        p_classes_categories : {
            type : undefined,
            default : null
        },
        p_image_path_classes : {
            type : String,
            default : 'storage/images/classes'
        },
        p_modal : {
            type : Boolean,
            default : false
        },
        p_application_details : {
            type : undefined,
            default : null
        },
        p_applied : {
            type : Boolean,
            default : false
        },
        p_applicable : {
            type : Boolean,
            default : true
        },
        p_user_school : {
            type : Number,
            default : 0
        },
        p_schedule_school : {
            type : String,
            default : ''
        },
        p_student_names : {
            type : undefined,
            default : null
        },
        p_applicable_school : {
            type : Boolean,
            default : true
        },



    },

    setup(props, context) {
        //Data
        //-----------------------------------
        const d_class = ref(props.p_class);
        const d_schedule = ref(props.p_schedule);
        const d_timetable = ref(props.p_timetable);
        const d_selected = ref(false);
        const d_deactive = ref(false);
        const d_disabled = ref(false);
        const d_show_option = ref(null);
        const d_option = ref(props.p_option);
        const d_arr_option_categories = ref(props.p_option_categories);
        const d_option_categories = ref({});
        const d_option_title = ref('');
        const d_option_category = ref(null);
        const d_selected_classes_category_id = ref(null);
        const d_selected_option_category_title = ref('');
        const d_selected_classes_category_title = ref('');
        const d_status = ref('');
        const d_capacity = ref({});
        const d_appplication = ref({});
        const d_numbers = ref({});
        const d_allow_application = ref(false);
        const d_schedule_school = ref([]);
        const d_student_names = ref(null);
        const d_applicable_school_names = ref('');


        const r_note = ref(null);


        //Mounted
        //-----------------------------------
        onMounted(() => {
            init();



        })

        const init = () => {

            //スケジュールから対象の学校を取得

            if(props.p_schedule_school){
                let arr_schools = [];
                if(props.p_schedule_school.indexOf(',') >= 0){
                    let arr_schools_temp = props.p_schedule_school.split(',')

                    for(let school_num in arr_schools_temp){
                        arr_schools.push(parseInt(arr_schools_temp[school_num]))
                    }
                }else{
                    arr_schools.push(parseInt(props.p_schedule_school));
                }


                d_schedule_school.value = arr_schools;
            }

            //対象の生徒を設定
            //申し込みできるかどうかの判定
            if(props.p_auth && props.p_verified && !props.p_applied && props.p_applicable_school){
                d_allow_application.value = true;
            }

            //対象の学校でない場合は生徒の呼称を設定
            if(props.p_auth){
                if(!props.p_applicable_school){
                    let arr_school_names = [];
                    for(let school_num in d_schedule_school.value){
                        arr_school_names.push(props.p_student_names[d_schedule_school.value[school_num]])
                    }
                    d_applicable_school_names.value = arr_school_names.join(',');

                }
            }




            //オプションの初期化
            if(d_option.value){
                d_option_title.value = d_option.value['title'];
            }

            //オプションカテゴリーの初期化
            for(let i in d_arr_option_categories.value){
                let option_category = d_arr_option_categories.value[i];
                let option_category_id = option_category['id'];
                d_option_categories.value[option_category_id] = option_category;
            }


            //申し込み可能人数と申し込み人数の初期化
            for(let i in props.p_classes_categories){
                let classes_category = props.p_classes_categories[i];
                let classes_category_id = classes_category.id;
                let capacity = classes_category.capacity;

                //受付人数の設定
                d_capacity.value[classes_category_id] = capacity;

                //申し込み人数の初期化
                d_appplication.value[classes_category_id] = 0;
            }

            //申し込み人数のカウント
            for(let i in props.p_application_details){
                let application_detail = props.p_application_details[i];
                let catgory_id = application_detail['classes_catgory_id'];
                if(d_appplication.value[catgory_id]){
                    d_appplication.value[catgory_id] += 1;
                }
            }
        };

        const selected = (selected_classes_category_id) => {

            d_selected.value = true;
            d_deactive.value = false;


            if(selected_classes_category_id && props.p_option_categories){
                d_show_option.value = true;


                //オプションカテゴリーの捜索
                for(let i in props.p_classes_categories){
                    if(props.p_classes_categories[i].id == selected_classes_category_id){

                        let target_option_category_id = props.p_classes_categories[i].option_category_id;
                        let option_category = d_option_categories.value[target_option_category_id];

                        d_selected_classes_category_title.value = option_category['title'];

                        break;

                    }
                }

            }else{
                d_show_option.value = false;
                d_selected_classes_category_id.value =  0;
                d_selected_option_category_title.value =  '';
            }

        };

        const clear_select = () => {
            d_selected.value = false;
            d_deactive.value = false;
            d_show_option.value = false;
            d_option.value = null
            d_option_category.value = null
            d_selected_classes_category_id.value = null;
            d_selected_option_category_title.value = '';

        };

        const deactive = () => {
            d_selected.value = false;
            d_deactive.value = true;
            d_show_option.value = false;
            d_option.value = null
            d_option_category.value = null
            d_selected_classes_category_id.value = null;
            d_selected_option_category_title.value = '';

        };

        const active = () => {
            d_deactive.value = false;
        };


        const select_classes = () => {
            //ログイン&認証済みの場合
            if(props.p_auth && props.p_verified && !props.p_applied) {
                if(d_selected.value){
                    context.emit('parent_unselect_classes', d_schedule.value['id'], d_timetable.value['id'], props.p_id, 0);
                }else{
                    if(!d_deactive.value){
                        if(props.p_modal){
                            open_classes_categories(props.p_id)
                        }else{
                            let classes_category_id = props.p_classes_categories[0]['id'];
                            context.emit('parent_select_classes', d_schedule.value['id'], d_timetable.value['id'], props.p_id, classes_category_id);

                        }
                    }

                }
            }else{
                //ログインされていない場合
                not_auth();

            }
        };

        //ログインしていない場合にトーストでメッセージを出す
        const not_auth = () => {
            context.emit('parent_not_auth');


        };



        const open_classes_categories = (classes_id) => {
            //$emit('parent_classes_categories', classes_id);
            context.emit('parent_classes_categories', classes_id);
        };




        return {
            d_class,
            d_schedule,
            d_timetable,
            d_selected,
            d_deactive,
            d_disabled,
            d_show_option,
            d_option,
            d_arr_option_categories,
            d_option_categories,
            d_option_title,
            d_selected_classes_category_id,
            d_selected_classes_category_title,
            d_status,
            d_capacity,
            d_appplication,
            d_numbers,
            d_allow_application,
            d_schedule_school,
            d_student_names,
            d_applicable_school_names,
            r_note,
            init,
            selected,
            clear_select,
            deactive,
            active,
            select_classes,
            not_auth,
            open_classes_categories,
            init,
            selected,
            clear_select,
            deactive,
            active,
            select_classes,
            open_classes_categories


        }
    },

    methods: {
        moment: function (date) {
            return moment(date).format('YYYY年M月D日');
        },
        moment_schedule: function (date) {
            return moment(date).format('YYYY年M月D日');
        },
        moment_start_date: function (date) {
            return moment(date).format('M月D日 kk:mm');
        },
        moment_timetable: function (date) {
            return moment(date).format('kk:mm');
        }

    }

})




</script>

<style scoped lang="scss">
    @import "../../../scss/modules/common/variables.scss";
    @import "../../../scss/modules/front/front.scss";
    $asset_url : '/assets';


    section {
        position: relative;
        margin: 0;



        &.class {
            &:last-of-type {
                border-bottom-left-radius: calc(
                    #{$border-radius} - #{$classes-border-width}
                );
                border-bottom-right-radius: calc(
                    #{$border-radius} - #{$classes-border-width}
                );
                .card {

                    border-bottom-left-radius: calc(
                        #{$border-radius} - #{$classes-border-width}
                    );
                    border-bottom-right-radius: calc(
                        #{$border-radius} - #{$classes-border-width}
                    );
                }
                //margin: 0 2px 1px;
                &.coming_soon,
                &.end,
                &.stop,
                &.full,
                &.card not_applicable_school
                &.disabled {
                    .status {
                        height: calc(100% + 2px);
                    }
                }
            }

            &.coming_soon,
            &.end,
            &.stop,
            &.full,
            &.applied,
            &.applied_timetable,
            &.not_applicable_school,
            &.grouped,
            &.disabled {
                margin: 0 !important;
                border-style: none;
                border-width: 0;
                //@include disable_condition();
                .card {
                    cursor: default !important;
                    box-shadow: 0 0 0 0;
                    .card-header {
                        padding-top: 2.5em;
                        h4 {
                            margin: 0.5em 0em 0;
                        }
                    }

                    .card-body {
                        //background-color: $color-fourthry;
                    }
                    //
                    .status {
                    }
                }
            }

            &.applied_timetable{
                .card {

                }
            }

            .status {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                color: $color-status;
                display: flex;
                justify-content:center;
                align-items: flex-start;
                font-size: 1.2em;

                span {
                    text-align: left;
                    position: absolute;
                    left: 0.9em;
                    top: 0.7em;
                }
            }

            &:not(.coming_soon, .end, .stop, .disabled, .full) {
                .card:not(.selected, .deactive) {
                    //&:hover:not(.deactive, .selected) {
                    &:hover {
                        /*
                        box-shadow: 0 0 0 #{$border-hover-thickness}
                            #{$hover-color-1};
                        background-color: $color-fourthry;
                        */
                    }
                }
            }
            .card {
                height: 100%;
                margin: 1.5px 0;
                margin: 0;
                border-radius: 0;
                transition: background-color 0.2s,
                    border-color 0.2s;
                //box-shadow: 0 0 0 #{$border-thickness} #fff;
                border: none;
                transition: box-shadow #{$transition-speed},
                    background-color #{$transition-speed},
                    border #{$transition-speed},
                    opacity #{$transition-speed};

                &.active {
                    box-shadow: 0 0 0 #{$border-hover-thickness}
                        #{$hover-color-1};
                }

                &.selected {

                    .card-body {
                        background-color: #{$color-white};
                    }
                }

                &.authed.arrival {
                    a{
                        cursor:pointer !important;
                    }
                    .card-header {
                        h4 {
                            margin-left:45px;
                        }

                        background: #{$color-white} url(#{$asset_url} + "/img/icon_checkbox.svg") no-repeat #{$checkbox_position};
                        background-size: #{$checkbox_size};
                        background-position: $checkbox_position;

                    }




                    &.selected {
                        .card-header {
                            background: #{$color-white} url(#{$asset_url} + "/img/icon_checkbox_checked.svg") no-repeat #{$checkbox_position};
                            background-size: #{$checkbox_size};
                            background-position: $checkbox_position;
                        }
                    }
                    a{
                        cursor:pointer;
                    }
                }

                &.deactive {
                    //@include disable_condition();
                    opacity: 0.5;
                    a {
                        cursor: default !important;
                    }

                    //background-color: #f00;
                }

                a {
                    cursor: default !important;
                    width: 100%;
                    height: 100%;
                    text-decoration: none;
                    color: $text-color-1;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                }
                .card-img-top {
                    //margin: -#{$class_padding} 0 #{$class_padding} -#{$class_padding};
                    //width: calc(200% + #{$class_padding} + #{$class_padding});
                }

                .card-header {
                    color: $color-secondary;
                    //background: #{$color-thirdry};
                    background-color:#{$color-white};
                    /*
                    border-top: #{$color-secondary} #{$border-thickness}
                        solid;

                    */
                    border-bottom: none !important;
                    &:first-child {
                        border-radius: 0 !important;
                    }

                    h4 {
                        font-size: 1.2em;
                        margin: 0.5em 0 0;
                        font-weight: bold;
                    }
                }
                .card-body {
                    //z-index: 0;
                    //background-color: $color-fourthry;
                    transition: background-color 0.2s;
                    //background-color: $color-fifthry;
                    //padding: 0;
                    padding: 0.5rem 1rem 1rem;
                    h4 {
                        margin: 1.5em 0 0.5em;
                        font-size: 1.2em;
                        //color: $red;
                        font-weight: bold;
                    }



                    .card-text {
                        position: relative;
                    }
                    .card-content {
                        //opacity: 1;
                        //transition: opacity 0.2s;
                    }


                    .note.collapse{
                        transition: height 0.2s;
                    }
                }

                .card-select {
                    width: auto;
                    margin: 0 auto;
                    justify-content: center;
                }
            }
        }
    }
</style>


