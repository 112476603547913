<template>
    <div>
        <div class="row mb-3">
            <p>授業</p>
            <div class="row col-12">
                <div class="col-6">
                    <input type="text" v-model="d_classes_keyword" class="form-control mb-3" id="classes_keyword"
                        name="classes_keyword" ref="r_classes_keyword">
                </div>
                <div class="col-6">
                    <button class="btn btn-outline-primary" @click="btnSearchClasses" ref="r_btn_search_classes">
                        授業を検索
                    </button>
                </div>
            </div>

            <div class="col-12">

                <select v-model="v_selected_classes" aria-label="授業" name="classes_id" class="form-select mb-3">
                    <option :value="d_blank_option_value">{{ d_blank_option_name }}</option>
                    <option v-for="d in d_searched_classes" :value='d.id'>
                        <template :set="classes_title = ''"></template>
                        <template :set="schedule_id= d.schedule_id"></template>
                        <template :set="timetable_id = d.timetable_id"></template>
                        <template :set="classes_title += d.id + ' : '"></template>
                        <template :set="classes_title += d.title"></template>
                        <template v-if="schedule_id in d_searched_schedules">
                            <template
                                :set="date = moment(d_searched_schedules[schedule_id].date + ' 00:00:00')"></template>
                            <template
                                :set="classes_title += ' (' +  date + ' ' + d_searched_schedules[schedule_id].title + ' '"></template>
                        </template>
                        <template v-if="timetable_id in d_searched_timetables">
                            <template
                                :set="classes_title += d_searched_timetables[timetable_id].title + ')'"></template>
                        </template>

                        {{ classes_title }}
                    </option>
                </select>
            </div>

        </div>

    </div>
</template>

<script>

    import { defineComponent, ref, onMounted, nextTick, inject } from "vue";
    import axios from "axios";
    import moment from 'moment';



    export default defineComponent({
        name: "AdminClasses",
        props: {
            p_classes_keyword: {
                type : String,
                default : ''
            },
            p_selected_classes: {
                type : Number,
                default : 0
            },
        },


        setup(props) {
            //Data
            //-----------------------------------
            const error = ref(null);
            const d_searched_classes = ref([]);
            const d_searched_schedules = ref({});
            const d_searched_timetables = ref({});

            const d_blank_option_value = 0;
            const d_blank_option_name = '選択してください';
            const d_classes_keyword = ref(null);


            //Reference
            //-----------------------------------
            const r_classes_keyword = ref(null);
            const r_btn_search_classes = ref(null);


            //v-model
            //-----------------------------------
            const v_selected_classes = ref(null);



            //injection
            //-----------------------------------
            const i_api_url = inject('api_url');

            //Methods
            //-----------------------------------

            //初期化
            const init = async () => {
                d_classes_keyword.value = props.p_classes_keyword;
                v_selected_classes.value = props.p_selected_classes;
                if (d_classes_keyword.value != '') {
                    searchClasses(d_classes_keyword.value);
                }
            };

            //授業の検索
            const btnSearchClasses =  (e) => {
                e.preventDefault();
                searchClasses(r_classes_keyword.value.value);
            }
            const searchClasses = async (keyword) => {
                let url = i_api_url + "/admin/classes/search_classes/get/?keyword=" + keyword;

                axios.get(url)
                    .then(response => {
                        d_searched_classes.value = response.data.classes;
                        d_searched_schedules.value = response.data.schedules;
                        d_searched_timetables.value = response.data.timetables;

                        //console.log(d_searched_classes.value)
                        //console.log(d_searched_schedules.value)
                        //console.log(d_searched_timetables.value)

                    }).catch(error => console.log(error))
            }

            //Load
            //---------------------------------------------
            const load = async () => {
                try {
                    await init();
                }catch (err) {


                }
            };

            nextTick(() => {
                load();
            });






            //ロード


            return {
                error,
                d_searched_classes,
                d_searched_schedules,
                d_searched_timetables,
                d_blank_option_value,
                d_blank_option_name,
                d_classes_keyword,
                r_classes_keyword,
                r_btn_search_classes,
                v_selected_classes,
                init,
                btnSearchClasses,
                searchClasses,
                load

            }
        },



        methods: {
            moment: function (date) {
                return moment(date).format('YYYY年MM月DD日');
            }
        },

        computed: {

        },



    })
</script>
