<template>
    <div>
        <div class="row mb-3">
            <p>スケジュール</p>
            <div class="col-lg-8 mb-3">
                <select v-model="d_selected_schedule" aria-label="スケジュール" name="schedule_id" class="form-select">
                    <option v-bind:value="p_blank_value">{{ p_blank_name }}</option>
                    <option v-for="d in d_schedules" :value='d.id'>{{ moment(d.date) }} {{ d.title }}</option>
                </select>

            </div>

            <div class="col-lg-4">
                <div class="form-check form-switch form-switch-lg d-flex justify-content-start align-items-center">
                    <input v-bind:checked="def_show_all()" ref="show_all_schedules" v-on:click="event_show_all_schedules" name="show_all_schedules" type="checkbox" value="1" class="form-check-input">
                    <label for="show_all_schedules" class="form-check-label ms-3">終了分も表示</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { defineComponent, ref, onMounted, inject } from "vue";
    import axios from "axios";
    import moment from 'moment';


    export default defineComponent({
        name: "AdminSchedule",
        props: {
            p_selected_schedule_id : {
                type : Number,
                default : 0
            },
            p_show_all_schedules: {
                type : Number,
                default : 0
            },
            p_blank_value : {
                type : Number,
                default : 0
            },
            p_blank_name : {
                type : String,
                default : 'すべて'
            },
            p_old: {
                type : undefined,
                default : null
            },
        },


        setup(props) {
            //Data
            //-----------------------------------
            const error = ref(null);
            const d_schedules = ref([]);
            const d_selected_schedule = ref(null);
            const d_show_all_schedules = ref(null);

            //injection
            //-----------------------------------
            const i_api_url = inject('api_url');

            //Methods
            //-----------------------------------
            const init = async () => {
                let all = 0;
                d_selected_schedule.value = props.p_selected_schedule_id;
                d_show_all_schedules.value = props.p_show_all_schedules;

                await get_schedules(d_show_all_schedules.value);

            };
            const event_show_all_schedules = (event) => {
                var all = 0
                if(event.target.checked){
                    all = 1;
                }
                get_schedules(all);


            };
            const get_schedules = async (all) => {
                //console.log(all)
                let url = i_api_url + "admin/schedules/get/?all=" + all;
                axios.get(url)
                    .then(response => {
                        //console.log(response.data.schedules)
                        d_schedules.value = response.data.schedules
                    })
                    .catch(error => console.log(error))


            };

            const def_show_all = () => {
                if(props.p_show_all_schedules == 1) {
                    return true;
                }else {
                    return false;
                }
            }

            //Load
            //---------------------------------------------
            const load = async () => {
                try {
                    await init();
                }catch (err) {


                }
            };

            //ロード
            load();

            return {
                error,
                d_schedules,
                d_selected_schedule,
                d_show_all_schedules,
                i_api_url,
                init,
                event_show_all_schedules,
                get_schedules,
                def_show_all,
                load
            }
        },

        methods: {
            moment: function (date) {
                return moment(date).format('YYYY年MM月DD日');
            }
        },

        computed: {

        },



    })
</script>
