
export default class Welcome {

    constructor(){
        this.init();
    }


    init() {

    }

}


