/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// jQuery

//import $ from 'jquery';
// BootstrapのJavaScript側の機能を読み込む/
import bootstrap from "bootstrap";
//Vue.js
import { provide, createApp } from 'vue';

//フロント
import FrontClasses from './Components/front/Classes.vue'
import FrontClassesCard from './Components/front/ClassesCard.vue'
import FrontClassesModalOption from './Components/front/ClassesModalOption.vue'
import FrontClassesModalAuth from './Components/front/ClassesModalAuth.vue'
import FrontApplicationButton from './Components/front/ApplicationButton.vue'
import FrontMessage from './Components/front/Message.vue'
import FrontMV from './Components/front/MV.vue'
import FrontRegister from './Components/front/Register.vue'
import FrontContents from './Components/front/Contents.vue'
import FrontConfirmParent from './Components/front/ConfirmParent.vue'


//管理画面
import AdminClasses from './Components/admin/Classes.vue';
import AdminClassesCategories from './Components/admin/ClassesCategories.vue';
import AdminClassesConditions from './Components/admin/ClassesConditions.vue';
import AdminOptionCategories from './Components/admin/OptionCategories.vue';
import AdminSchedules from './Components/admin/Schedules.vue';
import AdminTimetablesCategories from './Components/admin/TimetablesCategories.vue';
import AdminTimetables from './Components/admin/Timetables.vue';
import AdminClassesGroup from './Components/admin/ClassesGroup.vue';
import AdminClassesSortNew from './Components/admin/ClassesSortNew.vue';
import AdminClassesSortEdit from './Components/admin/ClassesSortEdit.vue';
//import AdminInformationTargetUsers from './Components/admin/InformationTargetUsers.vue';



//CustomJS
//common
import Common from './Modules/common';
//welcome
import Welcome from './Modules/welcome';
//admin
import Admin from './Modules/admin';


//Vue
const app = createApp({

    provide: {
        api_url: '/api/',
        app_base_url: '/',
        asset_url:'/assets/'
    },



    components: {
        FrontClasses,
        FrontClassesCard,
        FrontClassesModalOption,
        FrontClassesModalAuth,
        FrontApplicationButton,
        FrontMessage,
        FrontMV,
        FrontRegister,
        FrontConfirmParent,
        FrontContents,
        AdminClasses,
        AdminSchedules,
        AdminTimetablesCategories,
        AdminTimetables,
        AdminClassesCategories,
        AdminClassesConditions,
        AdminOptionCategories,
        AdminClassesGroup,
        AdminClassesSortNew,
        AdminClassesSortEdit,
        //AdminInformationTargetUsers
    }



})

app.mount("#app")

//Livewire
window.Livewire = require("../../public/vendor/livewire/livewire.js");

if (window.livewire) {
    console.warn(
        "Livewire: It looks like Livewire's @livewireScripts JavaScript assets have already been loaded. Make sure you aren't loading them twice."
    );
}


window.livewire = new window.Livewire();
window.Livewire = window.livewire;

if (env == 'local') {
    window.livewire_app_url = "";
    window.livewire.devTools(true);
} else {
    window.livewire_app_url = "";
    window.livewire.devTools(false);
}


window.livewire_token = "ZgcGN7YWdBf2wfwJQoDJ3pUHfwhBmAAlSoeBpQXD";

/* Make sure Livewire loads first. */

if (window.Alpine) {
    /* Defer showing the warning so it doesn't get buried under downstream errors. */
    document.addEventListener("DOMContentLoaded", function () {
        setTimeout(function () {
            console.warn(
                "Livewire: It looks like AlpineJS has already been loaded. Make sure Livewire's scripts are loaded before Alpine.\\n\\n Reference docs for more info: http://laravel-livewire.com/docs/alpine-js"
            );
        });
    });
}

/* Make Alpine wait until Livewire is finished rendering to do its thing. */
window.deferLoadingAlpine = function (callback) {
    window.addEventListener("livewire:load", function () {
        callback();
    });
};

let started = false;

window.addEventListener("alpine:initializing", function () {
    if (!started) {
        window.livewire.start();

        started = true;
    }
});

document.addEventListener("DOMContentLoaded", function () {
    if (!started) {
        window.livewire.start();

        started = true;
    }
});



try {
    window.Popper = require('popper.js').default;
    window.$ = window.jQuery = require('jquery');
    require('bootstrap');
} catch (e) { }

require('./bootstrap');


//AlpineJs
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();




window.onload = function () {
    var common = new Common();
    let welcome = new Welcome();
    var admin = new Admin();

    if(document.querySelectorAll('.quill_editor').length){
        var quill = new Quill('.quill_editor', {
            theme: 'snow',
            modules: {
              toolbar: {
                container: [
                  [{
                    font: []
                  }],
                  [{
                    header: [false, 1, 2, 3, 4, 5, 6]
                  }],
                  ["bold", "italic", "underline", "strike"],
                  [{
                    align: []
                  }],
                  ["blockquote", "code-block"],
                  /*

                  [{
                    list: "ordered"
                  }, {
                    list: "bullet"
                  }, {
                    list: "check"
                  }],
                  [{
                    indent: "-1"
                  }, {
                    indent: "+1"
                  }],
                  */
                  [{
                    color: []
                  }, {
                    background: []
                  }],
                  ["link",'image', "video"],
                  ["clean"],
                  ["source"]
                ],
                handlers: {
                    source: () => {
                        if (quill.txtArea.style.display === "") {
                            const html = quill.txtArea.value;
                            if (html === '<p><br/></p>') {
                                quill.html = null;
                            } else {
                                quill.html = html.replace(new RegExp('<p><br/>', 'g'), '<p>')
                            }
                            quill.pasteHTML(html);
                        }
                        quill.txtArea.style.display =
                        quill.txtArea.style.display === "none" ? "" : "none";
                    },

                    image: () => {
                        var range = quill.getSelection();
                        var value = prompt('What is the image URL');
                        if(value){
                            quill.insertEmbed(range.index, 'image', value, Quill.sources.USER);
                        }
                    }
                }
              }
            }
          });

          if (Quill.prototype.getHTML == undefined)
            Quill.prototype.getHTML = function() {
              return this.container.firstChild.innerHTML;
            };

          //fix for Quill v2
          if (Quill.prototype.pasteHTML == undefined)
            Quill.prototype.pasteHTML = function(html) {
              {
                this.container.firstChild.innerHTML = html;
              }
            }

          quill.txtArea = document.createElement("textarea");
          quill.txtArea.style.cssText =
            "width: 100%;margin: 0px;background: rgb(29, 29, 29);box-sizing: border-box;color: rgb(204, 204, 204);font-size: 15px;outline: none;padding: 20px;line-height: 24px;font-family: Consolas, Menlo, Monaco, &quot;Courier New&quot;, monospace;position: absolute;top: 0;bottom: 0;border: none;display:none;resize: none;";

          var htmlEditor = quill.addContainer("ql-custom");
          htmlEditor.appendChild(quill.txtArea);

          quill.on("text-change", (delta, oldDelta, source) => {
            var html = quill.getHTML();
            quill.txtArea.value = html;
          });

          quill.txtArea.value = quill.getHTML();
    }


}
