<template>
    <div class="my-4">
        <div v-html="p_message_front"></div>
        <template v-if="p_verified == 1 && p_auth == 1">
            <div v-html="p_message_front_logged_in"></div>
        </template>
        <template v-else>
            <div v-html="p_message_front_logged_out"></div>
        </template>
    </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, onMounted, onUpdated } from "vue";

export default defineComponent({
    props: {
        p_auth : {
            type : Number,
            default : 0
        },
        p_verify_success: {
            type : Number,
            default : 0
        },
        p_verified: {
            type : Number,
            default : 0
        },
        p_message_front : {
            type : String,
            default : ''
        },
        p_message_front_logged_in : {
            type : String,
            default : ''
        },
        p_message_front_logged_out : {
            type : String,
            default : ''
        },


    },
    setup(props, context) {


        const init = () => {
            //console.log(props.p_message_front)
            //console.log(props.p_message_front_logged_in)
        };

        //Mounted
        //-----------------------------------
        onMounted(() => {
            init();
        });

        return {

        }


    },

    methods: {

    }


})

</script>

<style scoped lang="scss">

</style>
