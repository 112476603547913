<template>
    <div>
        <!-- フラッシュメッセージ -->
        <div v-if="p_message != ''" class="alert alert-success flash_message my-3">
            <i class="bi bi-check"></i>  {{ p_message }}
        </div>

        <!--認証エラー-->


        <!--授業選択のエラー-->
        <div v-if="p_error_classes && p_error_classes.length > 0" class="alert alert-danger">
            <p>選択内容や申込内容に不備があります。修正して再度確認ボタンを押してください。</p>
            <ul>
                <li v-for="(d, i) in p_error_classes" :key="d" class="classes_group" style="list-style:disc;">{{p_error_classes[i]}}</li>
            </ul>
        </div>


    </div>
</template>

<script>
import { defineComponent, computed, ref, reactive, onMounted, onUpdated } from "vue";

export default defineComponent({
    props: {
        p_message : {
            type : String,
            default : ''
        },
        p_error : {
            type : Number,
            default : 0
        },
        p_error_classes : {
            type : undefined,
            default : null
        },

    },
    setup(props, context) {

    },
    methods: {

    }
})

</script>

<style scoped lang="scss">

</style>
