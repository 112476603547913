<template>
    <div>
        <!-- フラッシュメッセージ -->
        <div class="row" v-show="messege">
            <div  class="alert alert-success flash_message">
                <i class="bi bi-check"></i>  {{ this.messege }}
            </div>
        </div>
        <p>オプションカテゴリー</p>
        <ul class="list-group mb-3">
            <li class="list-group-item">
                <div class="row d-flex align-items-center">
                    <div class="col-md-1">ID</div>
                    <div class="col-md-11">オプションカテゴリー名</div>
                </div>
            </li>

            <li class="list-group-item" v-for="(item, i) in data_items" :id="'option_category_' + item.id" :ref="el => { if (el) category_edit[i] = el }">
                <div class="row">
                    <div class="col-md-1">
                        {{ item.id }}
                    </div>
                    <div class="col-md-11">
                        <div class="row" :id="'category_title_' + item.id">
                            <div class="col-md-10">
                                {{ item.title }}
                            </div>
                            <div class="col-md-1">
                                <button type="button" class="btn bi bi-pencil-fill btn_tooltip" data-bs-toggle="tooltip" data-bs-placement="top" title="編集" @click="open_edit_category" :data-id="item.id"></button>
                            </div>
                            <div class="col-md-1">
                                <button type="button" class="btn bi bi-trash-fill btn_tooltip" title="削除" data-bs-toggle="modal" data-bs-target="#modal_delete" :data-id="item.id" @click="set_delete_delete_category_data(item.id,item.title)"></button>
                            </div>
                        </div>
                        <div class="row" :id="'form_edit_category_' + item.id" v-show="form_edit_category">
                            <div class="col-md-10">
                                <input type="text" class="form-control" :id="'input_category_name_' + item.id" v-model="item.title" placeholder="カテゴリー名" :data-base-name="item.title">
                            </div>
                            <div class="col-md-1">
                                <button type="button" class="btn bi bi-save-fill btn_tooltip" title="保存" @click="update" :data-id="item.id"></button>
                            </div>
                            <div class="col-md-1">
                                <button type="button" class="btn bi bi-x-circle-fill btn_tooltip" data-toggle="tooltip" title="取消" v-on:click="cancel_edit_category" :data-id="item.id"></button>
                            </div>
                        </div>

                    </div>
                </div>
            </li>

            <li class="list-group-item" ref="category_add">
                <div class="row" v-show="btn_new_category">
                    <div class="col-md-1">
                        <button class="btn bi bi-plus-circle-fill px-0 btn_tooltip" v-on:click="open_new_category" data-bs-toggle="tooltip" title="追加"></button>
                    </div>
                </div>

                <div class="row" v-show="form_new_category">
                    <div class="col-md-1"></div>
                    <div class="col-md-11">
                        <div class="row">
                            <div class="col-md-10">
                                <input type="text" class="form-control" v-model="new_category_title" placeholder="カテゴリー名" id="new_category_title">
                            </div>
                            <div class="col-md-1">
                                <button type="button" class="btn bi bi-save-fill btn_tooltip"  title="保存" v-on:click="create"></button>
                            </div>
                            <div class="col-md-1">
                                <button type="button" class="btn bi bi-x-circle-fill btn_tooltip" title="取消" v-on:click="cancel_new_category"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <!-- Modal -->
        <div class="modal fade" id="modal_delete" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="my-4">「{{ delete_category_title }}」を削除します。この操作は取り消せません。<br>よろしいですか？</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">閉じる</button>
                    <button type="button" class="btn btn-danger" @click="delete_category" data-bs-dismiss="modal">削除</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import { defineComponent, computed, ref, reactive, watch, onMounted, onUpdated, onActivated, onBeforeUpdate, inject } from "vue";

    import axios from "axios";
    import Tooltip from 'bootstrap/js/dist/tooltip';

    export default defineComponent({
        name: "AdminOptionCategories",

        props: {
            p_option_id :  {
                type: Number, // Number型に限定
            },

            p_items : {
                type : Array,
            },

        },

        setup(props) {


            //Data
            //-----------------------------------
            const data_items = ref(props.p_items);
            const new_category_title = ref('');
            const btn_new_category = ref(true);
            const form_new_category = ref(false);
            const category_name = ref(false);
            const form_edit_category = ref(false);
            const delete_category_id = ref('');
            const delete_category_title = ref('');
            const messege = ref(null);
            const category_edit = ref([]);
            const category_add = ref(null);
            const modal_delete = ref(null);
            //injection
            //-----------------------------------
            const i_api_url = inject('api_url');





            // make sure to reset the refs before each update
            //-----------------------------------
            onBeforeUpdate(() => {
                //buttons.value = [];
            })

            //Mounted
            //-----------------------------------
            onMounted(() => {
                //初期は編集フォームを非表示
                let editor = document.querySelectorAll(".option_category_editor");
                if(editor){
                    let len = editor.length;
                    for (let i = 0; i < len; i++){
                        editor.item(i).style.display = 'none';
                    }
                }
                get_option_categories();

                let btn_add = category_add.value.querySelectorAll('.btn_tooltip')
                btn_add.forEach(btn => {
                    new Tooltip(btn)
                })



            });

            onUpdated(() => {

                //Tooltip.hide();
                for (let i = 0; i < category_edit.value.length; i++){
                    let el = category_edit.value[i];
                    let btns = el.querySelectorAll('.btn_tooltip')

                    btns.forEach(btn => {
                        new Tooltip(btn)
                    })
                }

            });

            onActivated(() => {

            });

            const open_new_category = (event) => {
                form_new_category.value = true;
                btn_new_category.value = false;
            };

            const cancel_new_category = (event) => {
                new_category_title.value = '';
                form_new_category.value = false;
                btn_new_category.value = true;

            };

            const open_edit_category = (event) => {
                var id = event.target.dataset.id;

                var name = document.getElementById('category_title_' + id);
                name.style.display = "none";

                var form = document.getElementById('form_edit_category_' + id);
                form.style.display = "";


            };

            const cancel_edit_category = (event) => {
                let id = event.target.dataset.id;


                let input = document.getElementById('input_category_name_' + id);
                let baseName = input.dataset.baseName;
                input.value = baseName;

                let name = document.getElementById('category_title_' + id);
                name.style.display = "";

                let form = document.getElementById('form_edit_category_' + id);
                form.style.display = "none";

            };


            const get_option_categories = () => {

                let url = i_api_url + "admin/option_categories/get/" + props.p_option_id;
                axios.get(url).then(response => {
                    data_items.value = response.data.categories;
                });


            };

            const create = async (event) => {
                let url = i_api_url + "admin/option_categories/create";

                let input = document.getElementById('new_category_title');
                let title = input.value;
                let arr = {
                    title : title,
                    option_id : props.p_option_id,
                }

                await axios.post(url,arr).then(response => {
                    new_category_title.value = '';
                    form_new_category.value = false;
                    btn_new_category.value = true;
                    if('messege' in response.data){
                        messege.value = response.data['messege'];
                    }

                    get_option_categories();

                })
            };

            const update = (event) => {
                let url = i_api_url + "admin/option_categories/update";

                let id = event.target.dataset.id;
                let input = document.getElementById('input_category_name_' + id);
                let title = input.value;

                let arr = {
                    id : id,
                    option_id : props.p_option_id,
                    title : title,
                }

                axios.put(url,arr).then(response => {
                    let id = arr["id"];
                    let name = document.getElementById('category_title_' + id);
                    name.style.display = "";
                    let form = document.getElementById('form_edit_category_' + id);
                    form.style.display = "none";

                    if('messege' in response.data){
                        messege.value = response.data['messege'];
                    }
                    get_option_categories();
                })
            };




            //カテゴリーの削除
            const set_delete_delete_category_data = (id,title) => {

                delete_category_id.value = id;
                delete_category_title.value = title;

                console.log(delete_category_id.value)
                console.log(delete_category_title.value)

            };

            const reset_delete_delete_category_id = () => {
                delete_category_id.value = '';
                delete_category_title.value = '';
            };

            const delete_category = () => {

                if(delete_category_id.value != ''){

                    let url = i_api_url + "admin/option_categories/delete/" + delete_category_id.value;
                    axios.delete(url).then(response => {
                        get_option_categories();
                        if('messege' in response.data){
                            messege.value = response.data['messege'];
                        }
                    })
                }
            };


            return {
                data_items,
                new_category_title,
                btn_new_category,
                form_new_category,
                category_name,
                form_edit_category,
                delete_category_id,
                delete_category_title,
                messege,
                i_api_url,
                open_new_category,
                cancel_new_category,
                open_edit_category,
                cancel_edit_category,
                get_option_categories,
                create,
                update,
                set_delete_delete_category_data,
                reset_delete_delete_category_id,
                delete_category,
                category_edit,
                category_add,
                modal_delete

            }
        },

        methods:{


        },
        computed: {
            isInput: function () {
                var btn_add = this.$refs.btn_add;
                if (btn_add.value) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    })
</script>
