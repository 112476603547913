<template>
    <div>
        <div class="row mb-3">
            <p>カテゴリー</p>
            <div class="col-md-6 mb-3">
                <select v-model="d_selected_timetable_category_id" aria-label="カテゴリー" name="category" class="form-select">
                    <option v-bind:value="0">すべて</option>
                    <option v-for="d in d_timetable_categoies" :value='d.id'>{{ d.title }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent, ref, onMounted, inject } from "vue";
    import moment from "moment";
    import axios from "axios";

    export default defineComponent({
        name: "AdminTimetablesCategories",

        props: {
            p_selected_timetable_category_id : {
                type : Number,
                default : 0
            },
            p_blank_value : {
                type : Number,
                default : 0
            },
            p_blank_name : {
                type : String,
                default : '未選択'
            },
        },

        setup(props) {
            //Data
            //-----------------------------------
            const error = ref(null);
            const d_timetable_categoies = ref([]);
            const d_selected_timetable_category_id = ref(props.p_selected_timetable_category_id);



            //injection
            //-----------------------------------
            const i_api_url = inject('api_url');

            //Mounted
            //-----------------------------------
            onMounted(() => {
                get_timetable_cateories();
            });

            //Methods
            //-----------------------------------
            const get_timetable_cateories = () => {
                let url = i_api_url + "admin/timetables/categories/";
                axios.get(url).then(response => {
                    d_timetable_categoies.value = response.data;
                });
            };
            const change_timetable_cateories = (event) => {
                var id= event.target.value;
                get_timetables(id,d_selected_timetable.value);
            };

            return {
                error,
                d_timetable_categoies,
                d_selected_timetable_category_id,
                i_api_url,
                get_timetable_cateories,
                change_timetable_cateories
            }

        },

        methods: {
            moment: function (date) {
                return moment(date).format('YYYY年MM月DD日');
            }
        },

    })
</script>
