//import $ from "jquery";

//Dropzone
//import "dropzone/dist/min/dropzone.min.css";
//import Dropzone from "dropzone";

export default class Admin {

    constructor() {
        this.init();

    }

    init() {

        //管理画面のJS
        let body_class = document.getElementsByTagName("body")[0].className.split(' ')
        if (body_class.indexOf('admin') > -1){




            //削除モーダル
            //------------------------------------------
            var modalDelete = document.getElementById('modal_delete')
            if(modalDelete){
                modalDelete.addEventListener('shown.bs.modal', function (e) {
                    var button = e.relatedTarget;

                    var id = button.dataset.deleteId;
                    var title = button.dataset.deleteTitle;
                    var modal = e.target;

                    var modal_id = modal.querySelector('#modal-delete-id');
                    var modal_title = modal.querySelector('#modal-delete-title');
                    var modal_input_id = modal.querySelector('input[name="id"]');
                    modal_input_id.value = id;

                    modal_id.innerHTML = id;
                    modal_title.innerHTML = title;
                })
            }

            //検索条件のクリア
            //----------------------------------------
            var btnSearchClear = document.getElementById('search_clear')
            if(btnSearchClear){
                btnSearchClear.addEventListener('click', (e) => {
                    var searchArea = document.getElementById('search_area')
                    if(searchArea){
                        var inputs = searchArea.querySelectorAll('input, select')
                    }
                    inputs.forEach(input => {
                        if(input.type != 'submit'){
                            if(input.type == 'checkbox' || input.type == 'radio'){
                                input.checked = false;
                            } else{

                                // val値を空にする
                                input.value = '';
                            }
                        }
                    });
                });
            }



            //お知らせ設定
            //----------------------------------------
            if (body_class.indexOf('information') > -1){
                //配信方法の変更
                /*
                let publish_method = document.getElementsByName('publish_method');
                publish_method.addEventListener('change', (e) => {
                    change_publish_method(e);
                });
                */

                /*ここは次期バージョンで実装予定なので、残しておく
                for (var i = 0; i < publishs.length; i++){
                    var item = publishs.item(i);
                    item.addEventListener('change', (e) => {
                        chnage_publish(e);
                    });
                }



                //配信条件の変更
                let publish_conditions = document.getElementsByName('publish_condition');
                for (var i = 0; i < publish_conditions.length; i++){
                    var item = publish_conditions.item(i);
                    item.addEventListener('change', (e) => {
                        chnage_publish_condition(e);
                    });
                }

                //ユーザーIDを指定
                let publish_condition_user_ids = document.getElementById('publish_condition_user_ids');
                publish_condition_user_ids.addEventListener('change', (e) => {
                    change_publish_condition_user_ids(e);
                });


                //メール設定の有効・無効化
                const chnage_publish = (e) => {
                    //メール配信が有効の場合はメール設定も有効にする。
                    let value = e.target.value;
                    let target_input = ['email_from', 'email_from_appearance', 'email_footer'];
                    let disabled = false;
                    if(value == 2){
                        disabled = true;
                    }
                    for(let i in target_input){
                        var email_setting = document.getElementById(target_input[i])
                        email_setting.disabled = disabled;
                    }
                }

                //配信条件の有効・無効化
                const chnage_publish_condition = (e) => {
                    let value = e.target.value;
                    let target_input = ['publish_condition_user_ids', 'publish_condition_user_ids_values'];
                    let disabled = false;
                    if(value == 1){
                        disabled = true;
                    }
                    for(let i in target_input){
                        var email_setting = document.getElementById(target_input[i])
                        email_setting.disabled = disabled;
                    }

                    let enable_user_ids = document.getElementById('publish_condition_user_ids').checked
                    if(enable_user_ids){

                    }else{

                    }
                    enable_publish_condition_user_id_value(enable_publish_condition_user_ids)


                }

                */

                //配信方法の変更
                const change_publish_method = (e) => {
                    let republish_include_mail = document.getElementsByName('publish_method');
                    if(republish_include_mail){
                        if(e.target.value != 3){
                            republish_include_mail.disabled = true;
                        }else{
                            republish_include_mail.disabled = false;
                        }
                    }
                }

                /*
                const change_publish_condition_user_ids = (e) => {
                    var enable = false
                    if(e.target.checked){
                        enable = true;
                    }
                    enable_publish_condition_user_id_value(enable)

                }

                const enable_publish_condition_user_id_value = (enable) => {
                    var element = document.getElementById('publish_condition_user_ids_values')
                    if(enable){
                        element.disabled = false;
                    }else{
                        element.disabled = true;
                    }
                }
                */



            }

            //ユーザーマスター、ユーザー検索
            //----------------------------------------
            //未ログインをチェックした場合は最終ログインの入力を無効にする
            if (body_class.indexOf('admin_user') > -1) {


                let never_logged_in = document.getElementById("never_logged_in");
                let logged_in_date_from = document.getElementById("logged_in_date_from");
                let logged_in_date_to = document.getElementById("logged_in_date_to");

                never_logged_in.addEventListener('change', function (event) {
                    if (event.target.checked) {
                        logged_in_date_from.disabled = true;
                        logged_in_date_to.disabled = true;
                    } else {
                        logged_in_date_from.disabled = false;
                        logged_in_date_to.disabled = false;

                    }
                    console.log(event.target.checked)


                })

            }










            //スケジュール設定
            //----------------------------------------
            if (body_class.indexOf('schedule') > -1){

                //CSVダウンロード
                if (body_class.indexOf('edit') > -1){
                    var check_type = document.getElementsByName('type');
                    var check_select = document.getElementsByName('select');
                    check_type.forEach(function(e) {
                        e.addEventListener("click", function () {
                            if (e.value == 1) {
                                //radio_disabled(check_select, true);
                                radio_disabled(check_select, false);
                            } else {
                                radio_disabled(check_select, false);
                            }
                        });
                    });
                }


                //画像サムネールの入れ替え
                //フォーム
                let image_pc_upload = document.getElementById('image_pc_upload');
                image_pc_upload.addEventListener('change', (e) => {
                    onChangeInputFile(e,'thumb_pc');
                });

                let image_sp_upload = document.getElementById('image_sp_upload');
                image_sp_upload.addEventListener('change', (e) => {
                    onChangeInputFile(e,'thumb_sp');
                });

                //画像のクリア
                let clear_image_pc = document.getElementById('clear_image_pc');
                clear_image_pc.addEventListener('click', (e) => {
                    ClearInputFile(e, 'image_pc', 'image_pc_upload', 'thumb_pc');
                });

                let clear_image_sp = document.getElementById('clear_image_sp');
                clear_image_sp.addEventListener('click', (e) => {
                    ClearInputFile(e, 'image_sp', 'image_sp_upload', 'thumb_sp');
                });




            }

            //クリップボードへのコピー
            var copy_to_clipboards = document.querySelectorAll('.copy_to_clipboard');
            //console.log(copy_to_clipboards.length)

            for (var i = 0;  i < copy_to_clipboards.length; i++) {
                copy_to_clipboards[i].addEventListener('click', function(){
                    let text = this.dataset.copyText;
                    copyToClipboard(text);
                })
            }
        }


        //ラジオボタンの無効化・有効化
        const radio_disabled = (radio_buttons, disabled) => {
            for (let button of radio_buttons) {
                button.disabled = disabled;
            }
        }

        //サムネール画像のチェンジ
        const onChangeInputFile = (e, img_id) => {
            if (e.target.files && e.target.files[0]) {
                const reader = new FileReader();
                reader.onload = function(e) {
                    document.getElementById(img_id).setAttribute('src', e.target.result);
                };
                reader.readAsDataURL(e.target.files[0]);
            }
        };

        //画像のクリア
        const ClearInputFile = (e, input_id, file_id, img_id) => {
            document.getElementById(input_id).value = '';
            document.getElementById(file_id).value = '';
            document.getElementById(img_id).setAttribute('src', window.assets_path + 'img/dammy_thumb.jpg');
        };

        //クリップボードにコピー
        function copyToClipboard(text) {
            console.log(text)
            navigator.clipboard.writeText(text);
        }

    }
}
