<template>
    <h3>{{ p_schedule['title'] }}</h3>
    <div class="sort">
        <draggable v-model="d_sorted_data" group="timetable" item-key="timetable" handle=".handle_timetable" @end="drag_timetable()">
            <template #item="{element}" >
                <ul class="list-group accordion accordion-flush" :id="'accordionPanelsStayOpenExample_' + element.timetable.id">
                    <template :set="timetable_id = element.timetable.id"></template>
                    <li class="accordion-item drag-item handle_timetable list-group-item list-group-item-secondary" :data-timetable-id="element.timetable.id">
                        <input type="hidden" name="timetable_ids[]" :value="timetable_id">
                        <h2 class="accordion-header" :id="'panelsStayOpen-heading_' + timetable_id">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#panelsStayOpen-collapse_' + timetable_id" aria-expanded="true" :aria-controls="'panelsStayOpen-collapse_' + timetable_id">
                                <i class="bi bi-list me-2"></i><span>{{ element.timetable.title }}</span>
                            </button>
                        </h2>
                        <ul :id="'panelsStayOpen-collapse_' + timetable_id" class="mb-0 accordion-collapse collapse drag-item list-group" :set="timetable_id_classes = timetable_id" :aria-labelledby="'panelsStayOpen-heading_' + timetable_id">
                            <draggable v-model="element.classes" :group="timetable_id_classes" :item-key="timetable_id_classes" handle=".handle_classes">
                                <template #item="{element}">
                                    <li class="handle_classes list-group-item list-group-item-light" :data-classes-id="element.id">
                                        <template :set="classes_id = element.id"></template>
                                        <input type="hidden" :name="'classes_ids[' + element.timetable_id + '][]'" :value="element.id">
                                        <i class="bi bi-list me-2"></i><span>{{ element.title }}</span>
                                    </li>
                                </template>
                            </draggable>
                        </ul>
                    </li>
                </ul>
            </template>
        </draggable>
    </div>

</template>
<script>

    import { defineComponent, ref, reactive, onMounted, onUpdated, inject } from "vue";
    import axios from "axios";
    import draggable from 'vuedraggable'

    export default defineComponent({
        name: "Adm inClassesSortEdit",
        components: {
            draggable
        },
        props: {
            p_sort_id : {
                type : Number,
                default : 0
            },
            p_sort : {
                type : undefined,
                default : null
            },
            p_schedule : {
                type : undefined,
                default : null
            },
            p_timetables : {
                type : undefined,
                default : null
            },
            p_classes : {
                type : undefined,
                default : null
            },
        },


        setup(props) {

            //Data
            //-----------------------------------
            const d_error = ref(null);
            const d_timetables = ref({});
            const d_sorted_timetable_ids = ref([]);
            const d_classes = ref({});
            const d_sorted_data = ref([]);
            const d_drag_timetable = ref(false)


            const d_test_data = ref([
                {
                    id: 1,
                    content: "テスト1",
                },
                {
                    id: 2,
                    content: "テスト2",
                },
                {
                    id: 3,
                    content: "テスト3",
                }
            ]);








            //reference
            //-----------------------------------


            //injection


            //Methods
            //-----------------------------------

            const init = async () => {

                //

                //時間割の授業の配列を初期化
                for(let i in props.p_timetables){
                    let timetable = props.p_timetables[i]
                    let timetable_id = timetable['id']
                    d_timetables.value[timetable_id] = timetable;
                    //d_sorted_classes_ids.value[timetable_id] = []
                }


                //時間割の並べ替えデータを初期化
                let temp_sorted_timetable_ids = [];
                if(props.p_sort['timetable_ids']){
                    temp_sorted_timetable_ids = props.p_sort['timetable_ids'].split(',');
                }


                //時間割がある場合は追加、ない場合は削除
                for(let i in temp_sorted_timetable_ids){
                    let timetable_id = parseInt(temp_sorted_timetable_ids[i])
                    if (timetable_id in d_timetables.value) {
                        d_sorted_timetable_ids.value.push(timetable_id);
                    }
                }

                //時間割の並べ替えデータの追加
                for(let i in d_timetables.value){
                    let timetable = d_timetables.value[i];
                    let timetable_id = timetable['id'];
                    if (d_sorted_timetable_ids.value.indexOf(timetable_id) == -1) {
                        d_sorted_timetable_ids.value.push(timetable_id);
                    }
                }

                //授業の並べ替えの整理
                for(let i in d_sorted_timetable_ids.value){
                    let timetable_id = d_sorted_timetable_ids.value[i]
                    let timetable = d_timetables.value[timetable_id]
                    let classes = props.p_classes['original'][timetable_id]
                    let sort_data = {}
                    sort_data['timetable'] = timetable;
                    sort_data['classes'] = classes;
                    d_sorted_data.value.push(sort_data);
                }

            };

            const drag_timetable = () => {

            }

            const drag_classes = () => {

            }

            onMounted(async () => {
                try {
                    await init();

                }catch (err) {
                    d_error.value = err.message
                }
            });

            return {
                d_error,
                d_timetables,
                d_sorted_timetable_ids,
                d_classes,
                d_sorted_data,
                d_test_data,
                d_drag_timetable,
                drag_timetable,
                drag_classes
            }



        },

        methods: {

        },

        computed: {

        },

    })

</script>

<style scoped lang="scss">

    ul {
        list-style-type: none;
    }
    li {
        list-style-type: none;
        cursor: pointer;
        padding: 10px;
        border: solid #ddd 1px;
    }


    .timetable{
        .classes{

        }
    }

    .drag-item {
        //background: rgb(233, 249, 255);
        margin: 10px 0;
        cursor: pointer;
    }
</style>
