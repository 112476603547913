function init(){

}


//条件チェック
function check_conditions(classes, application_numbers, capacities, classes_categories, conditions) {
    let status = null;

    for(let i in conditions){
        let condition = null
        if(i == 1){ //1.Max定員
            condition = max_capacity(classes, application_numbers, conditions[i])
        }else if(i == 2){

        }
        if(condition){
            status = condition;
            break;
        }
    }

    return status;

}


//1.Max定員
function max_capacity(classes, application_numbers, value) {
    let status = null;
    let application_number_total = 0;
    for(let i in application_numbers){
        application_number_total += application_numbers[i];
    }
    if(application_number_total >= value){
        status = 'stop';

    }
    return status;
}

//2.申し込みごとのカウント数
function application_step(classes, application_numbers, value) {

    let judge = false;

    return judge;
}



// 関数をエクスポートします。
export default {
    init,
    check_conditions,
    max_capacity,
    application_step,
};
