<template>
    <div>
        <div id="carousel_mv" class="carousel slide carousel-fade" data-bs-interval="false">
            <div class="carousel-inner d-flex align-items-stretch">
                <template :set="count = 0"></template>
                <div v-for="(d, i) in d_schedule_data" :key="d"
                    v-bind:class="[count == 0 ? 'carousel-item active' : 'carousel-item']" data-bs-interval="false">
                    <div class="images">

                        <!--　両方のMVがある場合 -->
                        <template v-if="d.image_pc && d.image_sp">
                            <img v-if="d.image_pc" class="d-block w-100 image_pc"
                                :src="p_image_path_schedules + d.image_pc" :alt="d.title">
                            <img v-if="d.image_sp" class="d-block w-100 image_sp"
                                :src="p_image_path_schedules + d.image_sp" :alt="d.title">
                        </template>

                        <!--　PC甩のMVのみの場合 -->
                        <template v-else-if="d.image_pc">
                            <img v-if="d.image_pc" class="d-block w-100" :src="p_image_path_schedules + d.image_pc"
                                :alt="d.title">
                        </template>

                        <!--　SP甩のMVのみの場合 -->
                        <template v-else-if="d.image_sp">
                            <img v-if="d.image_sp" class="d-block w-100" :src="p_image_path_schedules + d.image_sp"
                                :alt="d.title">
                        </template>
                    </div>
                    <div class="my-5">
                        <div class="lead" v-if="p_message_front_logged_in != ''" v-html="d.front_message"></div>
                    </div>
                    <template :set="count += 1"></template>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import { defineComponent, computed, ref, reactive, onMounted, onUpdated } from "vue";
import { Carousel } from "bootstrap";

export default defineComponent({
    props: {
        p_schedules : {
            type : Array,
            default : {}
        },
        p_image_path_schedules : {
            type : String,
            default : 'storage/images/schedules'
        },
        p_selected_schedule_id : {
            type : Number,
            default :0
        },
        p_message_front_logged_in : {
            type : String,
            default : ''
        },
    },

    setup(props, context) {
        const d_schedule_data = ref(props.p_schedules);
        const d_schedule_data_index = ref([]);
        const d_current_schedule_data = ref(null);
        const d_carousel_el = ref(null);
        const d_carousel = ref(null);

        const init = () => {

            set_schedule_data(d_schedule_data.value);
            d_carousel_el.value = document.querySelector('#carousel_mv')
            d_carousel.value = new Carousel(d_carousel_el.value)
        };



        const set_schedule_data = (schedule_data) => {
            let count = 0;

            for(let i in d_schedule_data.value){
                let data = d_schedule_data.value[i]
                d_schedule_data_index.value[data['id']] = i;
            }
        }


        const select_schedule = (schedule_id) => {
            if(d_schedule_data_index.value.length){
                let index = d_schedule_data_index.value[schedule_id];
                d_carousel.value.to(index);
            }
        };

        //Mounted
        //-----------------------------------
        onMounted(() => {
            init();
        });

        return {
            d_schedule_data,
            d_schedule_data_index,
            d_current_schedule_data,
            select_schedule
        }
    },
    methods: {

    }
})

</script>

<style scoped lang="scss">
    @import "../../../scss/modules/common/variables.scss";

    .image_sp{
        display: block !important;
        @media #{$bp-tab} {
            display: none !important;
        }
    }
    .image_pc{
        display: none !important;
        @media #{$bp-tab} {
            display: block !important;
        }
    }
    /*
    .carousel-item{
        transition: transform 1s ease-in-out;
    }
    */
    .carousel-fade{
        .carousel-item {
            transition: transform 1s ease-in-out !important;

        }
    }



</style>
