<template>
    <div>
        

        <div class="row mb-3">
            <p>オプション</p>
            <div class="col-12 mb-3">
                <select v-model="d_selected_option_id" v-on:change="change_option" ref="select_option" aria-label="オプション" name="option_id" class="form-select">
                    <option value="0">なし</option>
                    <option v-for="d in d_options" :value='d.id'>{{ d.title }} (オプションID:{{d.id}})</option>
                </select>
            </div>

            <p>定員<span class="badge bg-primary require my-1">必須</span></p>
            <div class="col-12 mb-3 input-capacity">
                <ul class="p-0">
                    <li v-for="(d, i) in d_options_categories" class="mb-3">
                        <div class="row">
                            <div class="col-md-8">
                                <p v-if="d.title" class="fw-bold m-0 text-left w-100">{{d.title}} </p>
                                <p v-if="d.option_category_id" class="m-0 text-left w-100">オプションカテゴリーID: {{ d.option_category_id }}</p>
                                <p v-if="d.id" class="m-0 text-left w-100">授業カテゴリーID: {{ d.id }} </p>
                            </div>
                            <div class="col-md-4">
                                <div class="row d-flex align-items-center">
                                    <div class="col-9">
                                        <input type="number" class="form-control" v-bind:name="'classes_categories['+ i + '][capacity]'" v-bind:value="d.capacity" min="0" max="9999">
                                        <input type="hidden" v-bind:name="'classes_categories['+ i + '][id]'" v-bind:value="d.id">
                                        <input type="hidden" v-bind:name="'classes_categories['+ i + '][classes_id]'" v-bind:value="d_classes_id">
                                        <input type="hidden" v-bind:name="'classes_categories['+ i + '][option_id]'" v-bind:value="d_selected_option_id">
                                        <input type="hidden" v-bind:name="'classes_categories['+ i + '][option_category_id]'" v-bind:value="d.option_category_id">
                                    </div>
                                    <div class="col-3">
                                        名
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

    </div>
</template>
<script>
    import { defineComponent, ref, reactive, onMounted, onUpdated, inject } from "vue";
    import axios from "axios";

    export default defineComponent({
        name: "AdminClassesCategories",
        props: {
            p_options: {
                type: [Array],
                default:()=>[]
            },
            p_selected_option_id: {
                type: Number,
                default:0
            },
            p_options_categories: {
                type: Array,
                //default:()=>[]
            },
            p_classes_id: {
                type: Number,
                default:0
            },
            p_option_id: {
                type: Number,
                default:0
            },
            p_classes_categories: {
                type : undefined,
                default : null
            },
            p_old: {
                type : undefined,
                default : null
            },
        },

        setup(props) {
            //const { p_selected_option_id } = ref(props);
            //const { p_options_categories } = ref(props);

            const d_options = ref([]);
            const d_selected_option_id = ref(props.p_selected_option_id);
            const d_options_categories = ref(props.p_options_categories);
            const d_classes_categories = ref([]);
            const d_classes_id = ref(props.p_classes_id);

            //injection
            //-----------------------------------
            const i_api_url = inject('api_url');





            const init = async () => {
                //授業カテゴリーの整理
                if(props.p_classes_categories){
                    for(let i in props.p_classes_categories){
                        let classes_category = props.p_classes_categories[i];
                        let option_category_id = classes_category['option_category_id'];
                        d_classes_categories.value[option_category_id] = classes_category;
                    }


                }

                if(d_selected_option_id){
                    d_selected_option_id.toExponentialvalue = 0;
                }
                await get_options();
                if(d_classes_id){
                    await get_classes_categories(d_classes_id.value, d_selected_option_id.value);

                }
                option_selected(d_selected_option_id.value);


            };

            const change_option = (event) => {
                const option_id = event.target.value;
                option_selected(option_id);
            };

            const option_selected = (id) => {

                get_option_categories(id);

            };

            const get_option_categories = async (id) => {
                let url = i_api_url + "admin/option_categories/get/" + id;
                await axios.get(url).then(response => {
                    d_options_categories.value = response.data.categories;
                    marge_class_catgories();
                })
            };

            const get_options = async () => {
                let url = i_api_url + "admin/options/get/";
                axios.get(url).then(response => {
                    d_options.value = response.data;

                })
            }

            const get_classes_categories = async (classes_id, option_id) => {

                let url = i_api_url + "admin/classes_categories/get/" + classes_id + '/' + option_id;
                await axios.get(url).then(response => {
                    d_classes_categories.value = response.data;
                })
            }

            const marge_class_catgories = () => {
                for (let i = 0; i < d_options_categories.value.length; i++){

                    var option_id = 0;
                    var option_category_id = 0;

                    if(d_selected_option_id){
                        option_id = d_selected_option_id;
                    }

                    if(d_options_categories.value[i]['id']){
                        option_category_id = d_options_categories.value[i]['id'];
                    }


                    d_options_categories.value[i]['option_id'] = option_id;
                    d_options_categories.value[i]['option_category_id'] = option_category_id;
                    d_options_categories.value[i]['classes_id'] = d_classes_id;



                    //

                    if(d_classes_categories.value){
                        if(option_category_id in d_classes_categories.value) {
                            d_options_categories.value[i]['id'] = d_classes_categories.value[option_category_id]['id'];
                            d_options_categories.value[i]['capacity'] = d_classes_categories.value[option_category_id]['capacity'];
                        }else{
                            d_options_categories.value[i]['id'] = '';
                        }
                    }else{
                        d_options_categories.value[i]['id'] = '';
                    }
                }
            }

            onMounted(() => {
                init();
            });

            onUpdated(() => {

            });

            return {
                //p_selected_option_id,
                //p_options_categories,
                d_options,
                d_selected_option_id,
                d_options_categories,
                d_classes_categories,
                d_classes_id,
                i_api_url,
                init,
                change_option,
                option_selected,
                get_option_categories,
                get_options,
                get_classes_categories,
                marge_class_catgories


            }
        },


    })
</script>

<style scoped lang="scss">
.input-capacity {
    ul{
        li{
            list-style: none;
        }
    }
}
</style>
